import React, { useEffect, useState, useRef } from 'react'

import { useOktaAuth } from '../hooks/useOktaAuth'
import {
  AnalyticsProvider,
  useAnalyticsContext,
} from '../Providers/AnalyticsProvider'

import ImpactGallery from '../component/ImpactGallery/ImpactGallery'
import ImpactGalleryMobile from '../component/ImpactGallery/ImpactGalleryMobile'
import { Loader } from '../component/ImpactGallery/Loader/Loader'
import { UnAuthorized } from '../component/ImpactGallery/BlankScreens/UnAuthorized'

import './impactGalleryExperience.css'

const ImpactGalleryExperience = () => {
  const [{ isAuthenticated, sessionData, isAuthenticating }, refreshToken] =
    useOktaAuth()
  const { createUser } = useAnalyticsContext()
  const galleryRef = useRef(null)
  const [[width, screenWidth], setSize] = useState([0, 0])
  useEffect(() => {
    if (galleryRef.current) {
      setSize([galleryRef.current.clientWidth, window.innerWidth])
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated)
      createUser(
        sessionStorage.getItem('fk-impact-okta-auth') || '',
        sessionData
      )
  }, [isAuthenticated, sessionData, createUser])

  return (
    <div className="fk-gallery-container" ref={galleryRef}>
      {isAuthenticating && <Loader />}
      {true ? (
        width ? (
          screenWidth > 768 ? (
            <ImpactGallery
              width={width}
              height={740}
              refreshToken={refreshToken}
            />
          ) : (
            <ImpactGalleryMobile
              width={width}
              height={740}
              refreshToken={refreshToken}
            />
          )
        ) : (
          <></>
        )
      ) : isAuthenticating ? (
        <></>
      ) : (
        <UnAuthorized />
      )}
      <div className="fk-footer-overlay" />
    </div>
  )
}

export default () => {
  return (
    <AnalyticsProvider widget="impact-gallery">
      <ImpactGalleryExperience />
    </AnalyticsProvider>
  )
}
