import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";

import { AutoComplete } from "../AutoComplete/AutoComplete";
import { getImpactSearch } from "../../../Services";
import { useAnalyticsContext } from "../../../Providers/AnalyticsProvider";
import FiltersIcon from "../../../assets/filters-icon.svg";

import "./header.css";
import { FiltersDropdown } from "../Filters/FiltersDropdown/FiltersDropdown";

const transformSearch = (searchValues) => {
  const { names = [] } = searchValues;
  return [...new Set(names)].filter((option) => !!option);
};

export const Header = forwardRef(
  (
    {
      selectedFilters = {},
      onFilterApplied = () => { },
      refreshToken = () => { },
      isMobile = false,
    },
    ref
  ) => {
    const { dispatchAnalyticsEvent } = useAnalyticsContext();
    const [searchOptions, setSearchOptions] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);

    const getSearchOptions = useCallback(() => {
      return getImpactSearch(
        (searchList) => {
          if (searchList) {
            setSearchOptions(transformSearch(searchList));
          }
        },
        (error) => {
          if (error.name === "UnauthorizedError") {
            refreshToken();
          }
        }
      );
    }, [refreshToken]);

    useImperativeHandle(
      ref,
      () => {
        return {
          getSearchOptions,
        };
      },
      [getSearchOptions]
    );

    useEffect(() => {
      getSearchOptions();
      // eslint-disable-next-line
    }, []);
    const handleRefresh = () => {
      getSearchOptions();
      dispatchAnalyticsEvent("GALLERY_REFRESH");
      onFilterApplied({});
      setOpenDropdown(false);
    };

    const handleFiltersConfirm = (filters) => {
      const { author, ...remainingFilters } = filters;
      getSearchOptions();
      onFilterApplied(remainingFilters);
      setOpenDropdown(false);
    };

    const handleSearchSubmit = (selectedFilter, type) => {
      dispatchAnalyticsEvent("FILTER_SEARCH", { selectedFilter, type });
      getSearchOptions();
      setOpenDropdown(false);
      if (selectedFilter) {
        return onFilterApplied({
          // ...selectedFilters,
          author: [selectedFilter],
        });
      }
      // eslint-disable-next-line
      const { author, ...remainingFilters } = selectedFilters;
      return onFilterApplied(remainingFilters);
    };

    const filtersCount =
      (selectedFilters?.hashtag?.length || 0) +
      (selectedFilters?.geo?.length || 0);

    return (
      <div
        className={`fk-header-wrapper ${isMobile ? "fk-header-wrapper-mobile" : ""
          }`}
      >
        <div className="fk-filter-wrapper">
          <div className="fk-filters-anchor">
            <div
              className="fk-filters-anchor-button"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <img src={FiltersIcon} alt="" className="fk-filters-anchor-icon" />
              <span className="fk-filters-anchor-text">{`Filters & Sort`}</span>
              {Boolean(filtersCount) && (
                <span className="fk-filters-count">{filtersCount}</span>
              )}
            </div>
          </div>
          <div className="fk-header-refresh-container">
            <div className="fk-header-refresh" onClick={handleRefresh}></div>
          </div>
        </div>

        <AutoComplete
          selectedFilter={selectedFilters?.["author"]?.[0]}
          options={searchOptions}
          onSubmit={handleSearchSubmit}
          isMobile
        />
        {openDropdown && (
          <FiltersDropdown
            selectedFilters={selectedFilters}
            onFiltersConfirm={handleFiltersConfirm}
            isMobile
          />
        )}
      </div>
    );
  }
);
