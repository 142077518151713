import React from 'react'

import './blankScreens.css'

export const NoContent = () => {
  return (
    <div className="fk-gallery-no-content">
      <p className="fk-no-content-heading">{'No Content Available'}</p>
      <p className="fk-no-content-text">
        {`No Content available for the Gallery. To create a new story click Create a Story in Shared Stories Section`}
      </p>
    </div>
  )
}
