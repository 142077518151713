import React, { useRef, useEffect, forwardRef } from "react";
import * as THREE from "three";
import {
  DepthOfFieldEffect,
  EffectPass,
  RenderPass,
  EffectComposer,
} from "postprocessing";

import { getEmbedThemeInfo } from "../../theme/Loader";
import { useAnalyticsContext } from "../../Providers/AnalyticsProvider";

import Item from "./Models/Item";
import { createImageTexture, getCanvasRelativePosition } from "./utils";
import ciscoFont from "../../assets/impact21/fonts/CiscoSansTT_Regular.json";
import { Loader } from "./Loader/Loader";

import "./impactGallery.css";

export const GalleryView = forwardRef(
  (
    { data, setLoading, width, height, onStoryClick, onPaginate = () => {} },
    ref
  ) => {
    let themeConfig = getEmbedThemeInfo();
    const { dispatchAnalyticsEvent } = useAnalyticsContext();
    const config = {
      pixelRatio: 1,
      startTime: Date.now(),
      scrollPos: 1500,
      distanceFactor: 300,
      fonts: [new THREE.Font(ciscoFont)],
      postsLimit: themeConfig.gallery.apiLimit,
      post: {
        size: {
          width: 200,
          height: 300,
        },
      },
      width,
      height,
      fog: {
        color: "#f5f5f5",
        near: 2100,
        far: 6000,
      },
      camera: {
        aspectRatio: width / height,
        fov: 35,
        near: 0.01,
        far: 6000,
      },
    };
    let scrollPos = config.scrollPos,
      firstItem = null,
      lastItem = null,
      maxScroll = 0,
      minScroll = 0,
      items = [],
      timeline = new THREE.Group(),
      textures = [],
      isPaginating = false,
      isSearchFocused = false,
      animationId = null;
    const target = useRef(null);
    const paginatorRef = useRef(null);
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      config.camera.fov,
      config.camera.aspectRatio,
      config.camera.near,
      config.camera.far
    );
    scene.fog = new THREE.Fog(
      config.fog.color,
      config.fog.near,
      config.fog.far
    );

    const renderer = new THREE.WebGLRenderer({
      powerPreference: "high-performance",
      alpha: true,
      antialias: false,
      stencil: false,
      depth: true,
      precision: "lowp",
    });
    renderer.autoClear = true;

    renderer.setSize(config.width, config.height);
    renderer.setPixelRatio(config.pixelRatio);
    renderer.setClearColor(new THREE.Color("#f5f5f5"), 1);
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    raycaster.near = camera.near;
    raycaster.far = camera.far;
    const composer = new EffectComposer(renderer);

    composer.setSize(config.width, config.height);

    const geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);

    const clearScene = () => {
      renderer.dispose();
      return scene.traverse((object) => {
        if (object.isMesh) {
          object.geometry.dispose();
          if (object.material.isMaterial) {
            object.material.dispose();
          } else {
            for (const material of object.material) {
              material.dispose();
            }
          }
        }
        if (object instanceof Item) {
          object.texture.dispose();
        }
      });
    };

    const updateTexture = async (objectId, objectData) => {
      const currentItem = items.find(({ itemIndex }) => itemIndex === objectId);
      if (currentItem) {
        const newTexture = await createImageTexture(
          objectData,
          renderer,
          config
        );

        const newItem = new Item({
          name: newTexture.data.getId(),
          config,
          texture: newTexture.texture,
          data: newTexture.data,
          itemIndex: objectId,
          geometry,
        });
        newItem.setPosition(currentItem.position);
        items.splice(objectId, 1, newItem);
        timeline.children.splice(objectId, 1, newItem);
        ref.current = {
          updateTexture,
        };
      }
    };

    useEffect(() => {
      if (target.current) {
        target.current.appendChild(renderer.domElement);
      }
      const dataPromises = data.map((item) =>
        createImageTexture(item, renderer, config)
      );

      Promise.all(dataPromises).then((values) => {
        textures.push(...values.filter((value) => value));
        if (textures.length) {
          createTimeline();
        } else {
          setLoading(false);
        }
      });

      return () => {
        renderer.domElement.removeEventListener("wheel", scroll);
        window.removeEventListener("keydown", keyScroll);
        window.removeEventListener("searchfocus", handleSearchFocus);
        renderer.domElement.removeEventListener("click", handleClick);
        renderer.domElement.removeEventListener("mousemove", handleClick);
        cancelAnimationFrame(animationId);
        clearScene();
      };
      // eslint-disable-next-line
    }, []);

    const setIsPagination = (value) => {
      isPaginating = value;
      if (paginatorRef.current) {
        if (value) {
          return paginatorRef.current.classList.add("fk-show");
        }
        return paginatorRef.current.classList.remove("fk-show");
      }
    };

    const handleSearchFocus = (e) => {
      isSearchFocused = e.detail.isFocused;
    };

    const createTimeline = () => {
      // Create Timeline Items
      const initialItems = textures.map((texture, index) => {
        return new Item({
          name: texture.data.getId(),
          config,
          texture: texture.texture,
          data: texture.data,
          itemIndex: index,
          geometry,
        });
      });
      items.push(...initialItems);

      // Add Items to timeline
      timeline.add(...items);
      // Set Initial Scroll
      firstItem = items[0];
      lastItem = items[items.length - 1];
      minScroll = lastItem.position.z + config.scrollPos;
      maxScroll = firstItem.position.z + config.scrollPos;
      const newScrollPosition = minScroll + (items.length > 5 ? 0 : 300);
      scrollPos = newScrollPosition;
      camera.position.z = scrollPos;
      // Attach Listeners
      renderer.domElement.addEventListener("wheel", scroll, { capture: true });
      window.addEventListener("keydown", keyScroll);
      window.addEventListener("searchfocus", handleSearchFocus);
      renderer.domElement.addEventListener("click", handleClick, {
        capture: true,
      });
      renderer.domElement.addEventListener("mousemove", handleClick, {
        capture: true,
      });

      const depthOfFieldEffect = new DepthOfFieldEffect(camera, {
        focusDistance: 0,
        focalLength: 2,
        bokehScale: 5.0,
      });

      scene.add(timeline);
      const renderPass = new RenderPass(scene, camera);
      const effectPass = new EffectPass(camera, depthOfFieldEffect);
      composer.addPass(renderPass);
      composer.addPass(effectPass);
      animate();
      ref.current = {
        updateTexture,
      };
      setLoading(false);
    };

    const updateTimeline = (type, item) => {
      setIsPagination(true);
      onPaginate(type).then((newData) => {
        if (newData.length) {
          const dataPromises = newData.map((currentItem) =>
            createImageTexture(currentItem, renderer, config)
          );
          return Promise.all(dataPromises)
            .then((values) => {
              const newTextures = values.filter((value) => value);
              if (newTextures.length) {
                let adjacentItem = item;
                const newItems = newTextures.map((texture, index) => {
                  const newItem = new Item({
                    name: texture.data.getId(),
                    config,
                    texture: texture.texture,
                    data: texture.data,
                    itemIndex: index,
                    adjacentItem,
                    creationType: type,
                    geometry,
                  });
                  adjacentItem = newItem;
                  return newItem;
                });
                const removalSize =
                  items.length + newItems.length > config.postsLimit
                    ? items.length + newItems.length - config.postsLimit
                    : 0;
                if (type === "newer") {
                  items = [...items.splice(removalSize), ...newItems];
                } else {
                  items = [
                    ...newItems.reverse(),
                    ...items.splice(0, config.postsLimit - removalSize),
                  ];
                }
                timeline.clear();
                clearScene();
                timeline.add(...items);
                firstItem = items[0];
                lastItem = items[items.length - 1];
                minScroll = lastItem.position.z + config.scrollPos;
                maxScroll = firstItem.position.z + config.scrollPos;
                if (scrollPos > minScroll) {
                  scrollPos = minScroll;
                }
                if (scrollPos < maxScroll) {
                  scrollPos = maxScroll;
                }
              }
              ref.current = {
                updateTexture,
              };
              setIsPagination(false);
            })
            .catch(() => setIsPagination(false));
        }
        setIsPagination(false);
      });
    };

    const animate = () => {
      animationId = requestAnimationFrame(animate);
      let delta = (scrollPos - camera.position.z) / 30;
      camera.position.z += delta;
      composer.render();
    };

    const scroll = (e) => {
      let delta = normalizeWheelDelta(e);
      const scrollDirection = Math.sign(-delta);
      navigate(delta * 30, scrollDirection);
      e.preventDefault();
      function normalizeWheelDelta(e) {
        if (e.detail && e.wheelDelta)
          return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1);
        // Opera
        else if (e.deltaY) return -e.deltaY / 60;
        // Firefox
        else return e.wheelDelta / 120; // IE,Safari,Chrome
      }
    };

    const keyScroll = (e) => {
      if (!isSearchFocused) {
        if (e.keyCode === 38) {
          navigate(-50, 1);
          e.preventDefault();
        }
        if (e.keyCode === 40) {
          navigate(50, -1);
          e.preventDefault();
        }
      }
    };

    const navigate = (scrollValue, scrollDirection) => {
      const newScrollValue = scrollPos + scrollValue;
      if (
        !isPaginating &&
        scrollDirection === 1 &&
        newScrollValue < maxScroll + 1500
      ) {
        dispatchAnalyticsEvent("PAGINATE_GALLERY", {
          type: "older",
          source: "WEB",
        });
        updateTimeline("older", firstItem);
      }
      if (
        !isPaginating &&
        scrollDirection === -1 &&
        newScrollValue > minScroll - 1500
      ) {
        dispatchAnalyticsEvent("PAGINATE_GALLERY", {
          type: "newer",
          source: "WEB",
        });
        updateTimeline("newer", lastItem);
      }
      if (
        newScrollValue >= maxScroll &&
        newScrollValue <= minScroll + (items.length > 5 ? 0 : 300)
      ) {
        dispatchAnalyticsEvent("GALLERY_SCROLL", {
          type: scrollDirection === 1 ? "older" : "newer",
          source: "WEB",
        });
        scrollPos = newScrollValue;
      }
    };

    const handleClick = (e) => {
      if (!renderer || e.target !== renderer.domElement) return;
      const normalizedPosition = getCanvasRelativePosition(
        e,
        renderer.domElement
      );
      mouse.x = (normalizedPosition.x / renderer.domElement.width) * 2 - 1;
      mouse.y = -(normalizedPosition.y / renderer.domElement.height) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      // raycast for items when in timeline mode
      const [intersected] = raycaster.intersectObjects(timeline.children, true);
      if (e.type === "mousemove") {
        if (intersected) {
          target.current.style.cursor = "pointer";
        } else {
          target.current.style.cursor = "default";
        }
        return;
      }
      if (intersected) {
        const storyData = intersected.object.parent.data;
        onStoryClick({
          data: storyData,
          texture: intersected.object.parent.texture.image,
          textureId: intersected.object.parent.itemIndex,
        });
        dispatchAnalyticsEvent("STORY_CLICK", storyData);
        return;
      }
    };

    return (
      <div className="fk-gallery-canvas" ref={target}>
        <div className="fk-paginator" ref={paginatorRef}>
          <Loader />
        </div>
      </div>
    );
  }
);
