import _ from "lodash";
import { getThemeName } from "../theme/Loader.js";
import dayjs from "dayjs";
import { getDateDiff } from "../utils.js";
import authorPic from "../assets/default.jpg";
export default class PostFlat {
  constructor(data) {
    this.data = data;
    this.author = data.author;
    this.source = data.source;
    this.createdAt = data.createdAt;
    this.id = data.id;
    this.text = data.text;
    this.type = data.type;
    this.url = data.url;
    this.media = data.media;
    this.metrics = data.metrics;
    this.nativeId = data.nativeId;
    this.hashtags = data.hashtags;
    this.userMetrics = null;

  }

  getHashtags() {
    return this.hashtags;
  }

  getMediaType() {
    return _.get(this.media, "[0].mediaType");
  }

  getMediaSize() {
    return _.get(this.media, "[0].sizes.full");
  }

  getId() {
    return this.id;
  }

  getNativeId() {
    return this.nativeId;
  }

  getType() {
    return this.type.toLowerCase();
  }

  getAuthorName() {
    return this.author.name;
  }

  isVideoMedia() {
    let url = _.get(this.media, "[0].url");
    const supportedVideoTypes = [
      "3gp",
      "3gpp",
      "mov",
      "mp4",
      "mp4v",
      "mpg4",
      "ogv",
      "qt",
      "webm",
    ];
    if (url) {
      let isVideo = supportedVideoTypes.filter(
        (extension) => url.search(extension) > -1
      );
      return isVideo.length > 0 ? true : false;
    }
    return false;
  }

  getVideoUrl() {
    let url = _.get(this.media, "[0].url");
    const supportedVideoTypes = [
      "3gp",
      "3gpp",
      "mov",
      "mp4",
      "mp4v",
      "mpg4",
      "ogv",
      "qt",
      "webm",
    ];
    if (url) {
      let isVideo = supportedVideoTypes.filter(
        (extension) => url.search(extension) > -1
      );
      return isVideo.length > 0 ? url : "";
    }
    return "";
  }

  isVerifiedAuthor() {
    return this.author.verified;
  }

  getAuthorHandle() {
    let alias = _.get(this.author, "alias", "");
    return alias ? `@${alias}` : "";
  }

  getAuthorPhoto() {
    return _.get(this.author, "photo", authorPic);
  }

  getCreatedAt() {
    switch (getThemeName()) {
      case "cisco-retail":
        return dayjs(this.createdAt).format("DD MMM");
      case "linkedin":
        return getDateDiff(this.createdAt);
      case "clmel":
        return dayjs(this.createdAt).format("DD MMM");
      default:
        return dayjs(this.createdAt).format("DD MMM YY");
    }
  }

  getMediaUrl() {
    let url1 = _.get(this.media, "[0].url");
    let url2 = this.getThumbnailUrl();
    let mediaType = this.getMediaType();
    if (url1 && mediaType === "image/jpeg") {
      return url1;
    }
    if (url2) {
      return url2;
    }
    return "";
  }

  getMediaUrls() {
    return this.media;
  }

  getThumbnailUrl() {
    return _.get(this.media, "[0].thumbUrl", "");
  }

  // getVideoUrl() {
  //   return _.get(this.media, '[0].url', '');
  // }

  getText(hyperlinks = false) {
    return this.text;
    // return _.truncate(this.text, {
    //   length: getCharLimit(),
    //   separator: /,? + \\./
    // });
  }

  getLikes() {
    return _.get(this.metrics, "likes");
  }

  getRetweets() {
    return _.get(this.metrics, "shares");
  }

  getUrl() {
    return this.url;
  }

  getSource() {
    return _.get(this.source, "name");
  }

  getUserMetrics() {
    return this.userMetrics;
  }

  getMetrics() {
    return this.metrics;
  }

  setMetrics(metrics) {
    this.metrics = metrics;
  }

  setUserMetrics(metrics) {
    this.userMetrics = metrics;
  }

  isPlayable() {
    return this.type === "video";
  }

  isPortrait() {
    try {
      let { w, h } = _.get(this.media, "[0].sizes.full");
      if (h > w) {
        return true;
      } else if (h === w) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("catch err");
      console.info("no media");
      return false;
    }
  }
}
