import React, { useState, useEffect, useRef } from 'react';
import {VideoRecorder} from './VideoRecorder';
import {VideoPlayer} from './VideoPlayer.js'

import {EXPERIENCE} from './../../experience/Stories';
import {TextArea} from './TextArea';
import {Input} from './Input';
import defaultUser from './../../assets/impact21/images/chat-bubble.PNG'

const LIMIT = 280;
const topic = 'impact21';

let videoUrl = '';
let videoThumb = '';
let hashtags = ['MyIMPACT'];
let hashtagSuggestions = ['FutureReady','CiscoIMPACT', 'InclusiveFuture', 'EmpoweredbyCisco'];
let story = 'My Cisco IMPACT story';
// let user = {};
// let userInfo = {};
// let oktaToken = '';
let duration = 0;
let width = 340;
let height = 450;
const resetData = () => {
  hashtags = ['MyIMPACT'];
  story = 'My Cisco IMPACT story';
  width = 340;
  height = 450;
}

export function RecordVideo(props) {
  // const [vidSrc, setVideo] = useState(null);
  // const [renderCamera, setRenderCamera] = useState(false);
  // const [permission, setPermission] = useState(true);
  function getVideo(vidObj) {
    // setVideo(vidObj.url);
    // video = vidSrc;
    // console.log('video src', video)
    videoUrl = vidObj.url;
    videoThumb = vidObj.thumb;
    duration = vidObj.duration;
    width = vidObj.width;
    height = vidObj.height;
    // console.log('video obj', vidObj, vidSrc);
    props.setExperience('PREVIEW_VIDEO');
  }

  // useEffect(() => {
  //   window.navigator.getUserMedia (
  //     // constraints
  //     {
  //        video: true,
  //        audio: true
  //     },
   
  //     // successCallback
  //     function(localMediaStream) {
  //       //  var video = document.querySelector('video');
  //       //  video.src = window.URL.createObjectURL(localMediaStream);
  //       //  video.onloadedmetadata = function(e) {
  //       //     // Do something with the video here.
  //       //  };
  //       setRenderCamera(true);
  //       setPermission(true);
  //     },
   
  //     // errorCallback
  //     function(err) {
  //      if(err) {
  //        setPermission(false);
  //        setRenderCamera(false);
  //        // Explain why you need permission and how to update the permission setting
  //      }
  //     }
  //  );
  // })
  return (
    <div className='container video-capture'>
    <div className='top-left-logo'></div>
      <div className='record-video-container'>
        <div className='sub-heading'>Record a Video</div>
        <div className='heading'>Video Recording</div>
        <div className='video-live-preview'>
          <VideoRecorder className='video' cb={getVideo}></VideoRecorder>
        </div>
      </div>
      <div className='info'>
          <div className='instructions'>
            Use a light background to optimize your video.
          </div>
        </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function RecordVideoPreview(props) {
  function setExperience(val) {
    props.setExperience(val);
  }
  return (
    <div className='container video-preview'>
    <div className='top-left-logo'></div>
      <div className='video-preview-container'>
        <div className='heading'>Record a Video</div>
        <div className='sub-heading'>Video Recording</div>
        <div className='video-preview'>
          {/* <video src={videoUrl} playsInline controls /> */}
          <VideoPlayer src={videoUrl} duration={duration} poster={videoThumb} />
        </div>
        <div className='action'>
          <div className='redo' onClick={() => setExperience(EXPERIENCE.RECORD_VIDEO)}>
            <i className='icon-fk icon-fk-video'></i>
            <div className='image video-icon-fk'></div>
            <span className='text'>{window.innerWidth <=768 ? 'Re-record' : 'Re-record Video'}</span>
          </div>
          <div className='continue' onClick={() => setExperience(EXPERIENCE.ADD_VIDEO_POST)}>
            <span className='text'>Continue</span>
          </div>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function AddVideoPost(props) {
  const [limit, setLimit] = useState(LIMIT);
  const [hashtag, setHashtags] = useState(hashtags);
  const [firstLanding, setFirstLanding] = useState(true);
  function getText(value) {
    story = value;
    // console.log('value', value, value.length)
    // console.log('limit', value.length);
    if(value.length > LIMIT) {
      return
    }
    setLimit(LIMIT - value.length);
  }
  function resetHashtags(arr) {
    hashtags=arr;
    setHashtags(arr)
  }
  function removeHashtag(tag) {
    // console.log('state', hashtag)
    let newHashtags = hashtag.filter(e => e!==tag);

    resetHashtags(newHashtags);
  }
  function getHashtagText(value) {
    addHashtags(value);
  }
  function goToReview() {
    setFirstLanding(false);
    // console.log('lmi', limit)
    if(limit < LIMIT) {
      props.setExperience(EXPERIENCE.VIDEO_POST_REVIEW)
    }
  }
  function addHashtags(tag) {
    let newHashtag = [...hashtag];
    // console.log(newHashtag.length, typeof newHashtag);
    if(newHashtag.length >= 4) {
      return;
    }
    newHashtag.push(tag)
    hashtags = newHashtag;
    setHashtags(newHashtag)
  }

  useEffect(() => {
    if(story) {
      setLimit(LIMIT - story.length)
    }
  }, [])
  // console.log('story', story);
  // console.log('hashtags', hashtags)
  return (
    <div className='container full-video'>
      <div className='top-left-logo'></div>
      <div className='full-video-container'>
        <div className='sub-heading'>Record a Video</div>
        <div className='heading'>Create Your Shared Story</div>
        <div className='add-post-container'>
          <div className='video-preview-and-retake'>
          <VideoPlayer src={videoUrl} duration={duration} poster={videoThumb} />
            <div className='action-retake'>
              <div onClick={() => props.setExperience(EXPERIENCE.RECORD_VIDEO)} className='btn-redo'>
                <i className='icon-fk icon-fk-video' />
                <div className='image video'></div>
                <span className='text'>Re-record Video</span>
              </div>
            </div>
          </div>
          <div className='add-story-container'>
            <div className='user-info'>
              <img src={defaultUser} alt="preview"/>
              <div className='username'>
                <span className='firstname'>{props.userInfo.first_name}</span>
                <span className='lastname'>{props.userInfo.last_name}</span>
              </div>
            </div>
            <div className='story-container'>
              <div className='heading'>Shared Story</div>
              <div className='sub-heading'>Add caption with up to 280 characters</div>
              <div className={`text-area ${(limit === LIMIT) && !firstLanding ? 'error': ''}`}>
              <TextArea 
                  placeholder="My Cisco IMPACT story  [ Shared Story Caption ]"
                  value={story} 
                  getText={getText} 
                  limit={LIMIT}>
                </TextArea>
                <div className='text-area-meta'>
                  <span className='error-msg'>You must enter a story to continue</span>
                  <span className='character-count'>{limit}</span>
                </div>
              </div>
            </div>
            <div className='hashtag-container'>
              <div className='heading'>Hashtags</div>
              <div className='sub-heading'>Type a Hashtag or Select a Suggested Hashtag (Up to 4 total)</div>
              <div className={`hashtags ${hashtags.length >=4 ? 'hideinput': ''}`}>
                {hashtags.map((hash, i) => 
                  <div className='hashtag-wrapper' key={hash}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    {i === 0 ? null : <i className='icon-fk icon-fk-cancel-circle' onClick={() => removeHashtag(hash)}></i> }
                  </div>)}
                <Input placeholder="Enter a #hashtag" getText={getHashtagText}></Input>
              </div>
              <div className='hashtags-suggestion'>
                {hashtagSuggestions.filter(e => !hashtags.includes(e)).map((hash) => (
                  <div key={hash} className='hashtag-wrapper' onClick={() => addHashtags(hash)}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    <i className='icon-fk icon-fk-plus'></i>
                    <div className='image icon-fk-add'></div>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
        </div>
        <div className='action-container full-video'>
          <div className='back' onClick={() => props.setExperience(EXPERIENCE.PREVIEW_VIDEO)}>
            <i className='icon-fk icon-fk-left-arrow'></i>
            <div className='image back-icon-fk'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={goToReview}>
            <span className='text'>Preview Story</span>
          </div>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function VideoPostReview(props) {
  const [publish, setPublishAction] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const videoCanvas = useRef(null);
  function setExperience(val) {
    props.setExperience(val);
  }

  function publishPost() {
    setPublishAction(true)
    setApiLoading(true);
  }

  useEffect(() => {
    if(publish) {
      // videoCanvas.width = 320;
      // videoCanvas.height = 480;
      var video = document.getElementById('video-player');
      var canvas = document.createElement("canvas");
      //console.log(canvas, canvas.nodeName);
      var ctx = canvas.getContext('2d');
      canvas.width = 320;
      canvas.height = 480;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      // var thumbUri = canvas.toDataURL('image/jpeg');
      // videoThumb = thumbUri;
      // console.log('make api call');
      // console.log('take use to download page');
      // const blob = dataURLtoBlob(image);
      // fetch(videoUrl).then(res => res.blob()).then(blob => {
        // console.log('videoUrl', videoUrl)
        // const videoBlob = new Blob([videoUrl], {"type": `video/mp4`})
        // console.log('videoBlob', videoBlob);
        Promise.all([
          fetch(videoUrl).then(res => {
            // console.log('blob details', res.blob());
            return res.blob()
          }),
          fetch(videoThumb).then(res => res.blob())
        ])
        .then(values => {
          const videoBlob = values[0];
          console.log('video blog', videoBlob);
          const thumbBlob = values[1];
          console.log('image blog', thumbBlob)
          const formData = new FormData();
          formData.append("type", "video");
          formData.append("media", videoBlob);
          formData.append("thumb", thumbBlob)
          formData.append("story", story);
          formData.append("hashtags", JSON.stringify(hashtags));
          formData.append("author", JSON.stringify({name: props.userInfo.full_name, email: props.userInfo.email_address}));
          formData.append("width", width);
          formData.append("height", height);
          formData.append("isIOS", !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
          // return;
          fetch(`https://api.fankave.com/cmsa/stories/${topic}/publish?x-access-token=${props.token}`, {
            method: 'POST',
            body: formData
          }).then(e => {
            console.log('published successfully', e);
            setExperience(EXPERIENCE.DOWNLOAD_VIDEO)
          }).catch(err => {
            console.error("Error: ", err);
            setExperience(EXPERIENCE.DOWNLOAD_VIDEO)
          });
        })
        .catch(err => {
          console.error('Error: ', err);
        })
    }
  })
  return (
    <div>
    <div className='container video-review'>
    <div className='heading-out'>Preview Story</div>
    <div className='top-left-logo'></div>
      <canvas ref={videoCanvas} width={320} height={480} style={{display: 'none'}}/>
      <div className={`spinner ${apiLoading ? '' : 'hide'}`}></div>
      <div className='video-post-preview-container'>
        <div className='video-preview'>
        <VideoPlayer src={videoUrl} duration={duration} poster={videoThumb} />
        </div>
        <div className='story'>
          {story}
        </div>
        <div className='hashtags'>
        {hashtags.map((hash, i) => 
                  <div className='hashtag-wrapper' key={hash}>
                    <div className='hashtag'>{`#${hash}`}</div>
                  </div>)}
        </div>
        <div className='user-info'>
          <img src={defaultUser} alt="preview"/>
            <div className='username'>
              <span className='firstname'>{props.userInfo.first_name}</span>
              <span className='lastname'>{props.userInfo.last_name}</span>
            </div>
          </div>
      </div>
      <div className='action'>
        <div className='back' onClick={() => setExperience(EXPERIENCE.ADD_VIDEO_POST)}>
          <i className='icon-fk icon-fk-left-arrow'></i>
          <div className='image back-icon-fk'></div>
          <span className='text'>Back</span>
        </div>
      <div className='continue' onClick={publishPost}>
        <span className='text'>Publish Story</span></div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
    </div>
    <div className='footer'></div>
    </div>
  )
}

export function VideoDownload(props) {
  function setExperience(val) {
    resetData();
    props.setExperience(val);
  }
  return (
    <div className='container video-download'>
      <div className='top-left-logo'></div>
      <div className='title'>
      Shared Story Posted
      </div>
      <div className='tagline'>
      Great news, your story is out there for all to see! Head over to the Shared Stories gallery to see how it looks and explore other stories.  Or, if you have more to say, create another one.
      </div>
      <div className='video-download-container'>
        <div className='video-download-preview'>
          <img src={videoThumb} alt='preview' />
          <div className='filename'>Video.mp4</div>
          <a href={videoUrl} download="video.mp4">
          <div className='download'>
            <i className='icon-fk icon-fk-download'/>
            <div className='dwnld-img' download="video.mp4" href={videoUrl}>
              <div className='image icdownload'></div>
              Download
            </div>
          </div>
          </a>
        </div>
        <div className='action'>
          <div className='close' onClick={() => {window.close()}}>{`Close & View Stories`}</div>
          <div className='redo' onClick={() => setExperience('')}>Create a New Story</div>
        </div>
      </div>
      <div className='footer'></div>
    </div>
  )
}
