import React, { Component } from 'react';
import { getEmbedExperienceText, getEmbedThemeInfo } from '../theme/Loader';
import lodash from 'lodash';

import './leaderboard.css';
import { getLeaderboard } from '../Services';
import { triggerSAEvent } from '../utils';
import defaultUser from './../assets/default.jpg';
// import { Container } from '../component/Container';

export default class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.info = getEmbedThemeInfo();
    this.state = {
      allTweeters: [],
      fadeIn: false,
      fadeOut: false
    }
  }

  apiLeaderboard = (category) => {
    getLeaderboard(category)
      .then(e => {
        // console.log('e', e);
        this.setState({ allTweeters: e.sort((a, b) => a.score - b.score), fadeIn: true, fadeOut: false })
      })
  }

  fetchApi = (category) => {
    this.setState({ fadeIn: false, fadeOut: true }, () => this.apiLeaderboard(category));
  }

  schedule = (category, refreshRate) => {
    this.interval = setInterval(() => this.fetchApi(category), refreshRate * 1000);
  }

  componentDidMount() {
    let { leaderboard: { refresh, refreshRate } } = getEmbedThemeInfo();
    let category = this.props.category;
    this.fetchApi(category);
    if (refresh) {
      this.schedule(category, refreshRate);
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      clearInterval(this.interval)
      this.fetchApi(this.props.category);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }


  getAllTweeters() {
    return lodash(this.state.allTweeters).sortBy(["score", "name"]).reverse().value();
  }

  getTopThreeTweeters() {
    var allTweeters = this.getAllTweeters();

    return lodash.take(allTweeters, 3);
  }

  getRemainingTweeters() {
    var allTweeters = this.getAllTweeters();
    return lodash(allTweeters).drop(3).take(5).value();
  }

  back = () => {
    // console.log('-goback container-', this.props.prevExperience);
    if (!this.props.prevExperience) {
      return;
    }
    this.props.setExperience(this.props.prevExperience)
    this.props.setCategory('')
    triggerSAEvent(`back to lounge from ${this.props.category ? 'cisco-' : 'attendee '}leaderboard`)
  };

  cisco = () => {
    // console.log('-goback container-', this.props.prevExperience);
    if (!this.props.prevExperience) {
      return;
    }
    // this.props.setExperience('cisco-leaderboard')
    this.props.category ? this.props.setCategory() : this.props.setCategory('cisco')
    triggerSAEvent(`back to lounge from ${this.props.category ? 'cisco-' : 'attendee '}leaderboard`)
  };

  getText(key, category) {
    if (category) {
      return getEmbedExperienceText('cisco-leaderboard', key)
    }
    return getEmbedExperienceText('leaderboard', key);
  }
  tweetAction = () => {
    let hashtags = this.getText('leaderboardHashtag');
    window.open(`https://twitter.com/intent/tweet?hashtags=${hashtags.join(',')}`, '', "height=400,width=400");
    triggerSAEvent(`post from ${this.props.category ? 'cisco' : 'attendee'} leaderboard`)
  }

  renderHeader = (category) => {
    return (
      <div className='header'>
        <div className='back' onClick={this.back}>
          <i className={`icon-fk ${this.getText('back-icon-fk')}`} onClick={this.back} />
          <div className='back-label'>{this.getText('back-text')}</div>
        </div>
        <div className="leaderboard-title title">{this.getText('title', category)}</div>
        <div className='action' onClick={this.tweetAction}>
          <i className={this.getText('action-icon-fk')}></i>
          {this.getText('action-text')}
        </div>
      </div>
    )
  }

  render() {
    let category = this.props.category;
    var topThreeTweeters = this.getTopThreeTweeters();
    var remainingTweeters = this.getRemainingTweeters();
    //  if (this.state.allTweeters.length <= 0) {
    //   return <div className='spinner'></div>;
    // }
    return (
      // <Container className={`leaderboard ${category ? category : ''}`} experience={this.props.experience} {...this.props}>
      <div className={`leaderboard-container ${this.props.category}`}>
        {this.renderHeader(category)}
        {this.state.allTweeters.length <= 0 ?
          <div className='spinner'></div> :
          <div className={`leaderboard-content ${this.state.fadeIn ? 'fade-in' : ''} ${this.state.fadeOut ? 'fade-out' : ''}`}>
            <div className='top'>
              {topThreeTweeters.map((e, i) => <Post key={e.handle} data={e} rank={i} top={true} category={this.props.category} />)}
            </div>
            <div className='rest'>
              {remainingTweeters.map((e, i) => <Post key={e.handle} data={e} rank={i} category={this.props.category} />)}
            </div>
            <div className='btn' onClick={this.cisco}>
              <div className='cisco-btn'>{this.props.category ? 'View Leaderboard' : 'View Cisco Leaderboard'}</div>
            </div>
          </div>
        }
      </div>
    )
  }
}


const trimUsername = (username = '') => {
  let { leaderboard: { char_limit } } = getEmbedThemeInfo();
  if (username.length >= char_limit) {
    let displayUsernameLength = char_limit - 3;
    return username.slice(0, displayUsernameLength) + '...';
  } else {
    return username;
  }
}

const openUser = (url, category) => {
  window.open(url, "_blank");
  triggerSAEvent(`visit ${category ? 'cisco' : 'attendee'} profile`);
};

const Post = ({ data, rank, top, category }) => {
  let rankToDisplay = '';
  if (top) {
    rankToDisplay = rank + 1;
  } else {
    rankToDisplay = rank + 4;
  }
  return (
    <div className='post'>
      <div className='post-img' onClick={() => openUser(`https://twitter.com/${data.handle}`, category)}>
        <img src={data.img[0]} alt='user' />
        <div className='pic' style={{ backgroundImage: `url(${data.img || defaultUser})` }} />
      </div>
      <div className='post-body'>
        <div className='medal'></div>
        <div className='post-content'>
          <div className='rank'>{rankToDisplay}</div>
          <div className='author-name'>{trimUsername(data.name)}</div>
          <div className='author-handle'>@{trimUsername(data.handle)}</div>
        </div>
        <div className='post-footer'>
          <div className='post-count'><i className='icon-fk-twitter-1' /><span>{data.count}</span></div>
        </div>
      </div>
    </div>
  )
}
