import React from 'react'

export const MouseScroll = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="100"
      viewBox="0 0 33 100"
      className="fk-mouse-scroll-icon"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#1E4471">
          <g>
            <g>
              <g>
                <path
                  fillRule="nonzero"
                  d="M16.327 28.571c6.753 0 12.244 5.492 12.244 12.245v16.327c0 6.753-5.491 12.245-12.244 12.245-6.754 0-12.245-5.492-12.245-12.245V40.816c0-6.753 5.491-12.245 12.245-12.245zm0-4.081C7.31 24.49 0 31.8 0 40.816v16.327c0 9.016 7.31 16.326 16.327 16.326 9.016 0 16.326-7.31 16.326-16.326V40.816c0-9.016-7.31-16.326-16.326-16.326zm0 18.367c-1.692 0-3.062-1.371-3.062-3.061s1.37-3.061 3.062-3.061c1.691 0 3.06 1.371 3.06 3.06 0 1.69-1.369 3.062-3.06 3.062z"
                  transform="translate(-537 -830) translate(108 592) translate(369 238) translate(60)"
                />
                <path
                  d="M12.884 102.897l9.182-9.393c.32-.31.447-.68.447-1.05 0-.372-.128-.742-.447-1.051l-9.182-9.394c-.574-.556-1.53-.617-2.105 0-.637.557-.701 1.484-.064 2.102l8.163 8.342-8.163 8.343c-.637.618-.573 1.545.064 2.101.574.618 1.53.556 2.105 0z"
                  transform="translate(-537 -830) translate(108 592) translate(369 238) translate(60) rotate(90 16.39 92.453)"
                />
                <path
                  d="M12.884 25.474l9.182-9.394c.32-.309.447-.68.447-1.05 0-.371-.128-.742-.447-1.05l-9.182-9.394c-.574-.556-1.53-.618-2.105 0-.637.556-.701 1.483-.064 2.101l8.163 8.343-8.163 8.342c-.637.618-.573 1.545.064 2.102.574.617 1.53.556 2.105 0z"
                  transform="translate(-537 -830) translate(108 592) translate(369 238) translate(60) rotate(-90 16.39 15.03)"
                />
                <path
                  d="M12.884 17.31l9.182-9.393c.32-.309.447-.68.447-1.05 0-.371-.128-.742-.447-1.051l-9.182-9.393c-.574-.556-1.53-.618-2.105 0-.637.556-.701 1.483-.064 2.1l8.163 8.343-8.163 8.343c-.637.618-.573 1.545.064 2.101.574.618 1.53.556 2.105 0z"
                  transform="translate(-537 -830) translate(108 592) translate(369 238) translate(60) rotate(-90 16.39 6.866)"
                />
                <path
                  d="M12.884 94.734l9.182-9.393c.32-.31.447-.68.447-1.051 0-.37-.128-.742-.447-1.05l-9.182-9.394c-.574-.556-1.53-.618-2.105 0-.637.556-.701 1.483-.064 2.101l8.163 8.343-8.163 8.343c-.637.618-.573 1.545.064 2.1.574.619 1.53.557 2.105 0z"
                  transform="translate(-537 -830) translate(108 592) translate(369 238) translate(60) rotate(90 16.39 84.29)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const KeyScroll = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="86"
      viewBox="0 0 132 86"
      className="fk-key-scroll-icon"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#1E4471" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M39.404 85.636H2.05c-.729 0-1.367-.638-1.367-1.367V46.913c0-.729.638-1.366 1.367-1.366h37.355c.73 0 1.367.637 1.367 1.366V84.27c0 .729-.638 1.367-1.367 1.367zM3.416 82.902h34.622V48.28H3.416v34.622z"
                    transform="translate(-822 -834) translate(108 592) translate(369 238) translate(334 4) translate(11.5)"
                  />
                  <path
                    d="M26.376 76.433c.637-.546.637-1.549.09-2.186l-8.2-8.656 8.2-8.655c.547-.638.547-1.64-.09-2.187-.547-.638-1.55-.547-2.096 0l-9.293 9.749c-.365.364-.456.729-.456 1.093 0 .365.091.729.456 1.093l9.293 9.75c.547.546 1.549.637 2.096 0zM85.771 46.822v37.356c0 .729-.638 1.366-1.367 1.366H47.05c-.729 0-1.367-.637-1.367-1.366V46.822c0-.729.638-1.366 1.367-1.366h37.355c.82 0 1.367.637 1.367 1.366zm-2.733 36.08V48.28H48.416v34.622h34.622z"
                    transform="translate(-822 -834) translate(108 592) translate(369 238) translate(334 4) translate(11.5)"
                  />
                  <path
                    d="M76.569 59.851c-.547-.638-1.549-.638-2.187-.091l-8.655 8.2-8.656-8.2c-.638-.547-1.64-.547-2.187.091-.637.547-.546 1.549 0 2.096l9.75 9.293c.364.364.728.456 1.093.456.364 0 .729-.092 1.093-.456l9.749-9.293c.638-.547.729-1.458 0-2.096zM45.682 39.178V1.822c0-.729.638-1.366 1.367-1.366h37.355c.73 0 1.367.637 1.367 1.366v37.356c0 .729-.638 1.366-1.367 1.366H47.05c-.729 0-1.367-.546-1.367-1.366zm2.734-35.99v34.623h34.622V3.19H48.416z"
                    transform="translate(-822 -834) translate(108 592) translate(369 238) translate(334 4) translate(11.5)"
                  />
                  <path
                    d="M54.884 26.149c.547.638 1.55.638 2.187.091l8.656-8.2 8.655 8.2c.638.547 1.64.547 2.187-.091.638-.547.547-1.549 0-2.096L66.82 14.76c-.364-.364-.729-.456-1.093-.456-.365 0-.73.092-1.094.456l-9.749 9.293c-.546.547-.637 1.55 0 2.096zM90.682 84.269V46.913c0-.729.638-1.366 1.367-1.366h37.355c.73 0 1.367.637 1.367 1.366V84.27c0 .729-.638 1.367-1.367 1.367H92.05c-.729 0-1.367-.638-1.367-1.367zm37.447-36.08H93.507V82.81h34.622V48.19z"
                    transform="translate(-822 -834) translate(108 592) translate(369 238) translate(334 4) translate(11.5)"
                  />
                  <path
                    d="M105.078 76.433c-.638-.546-.638-1.549-.091-2.186l8.2-8.656-8.2-8.655c-.547-.638-.547-1.64.09-2.187.547-.638 1.55-.547 2.096 0l9.294 9.749c.364.364.455.729.455 1.093 0 .365-.09.729-.455 1.093l-9.294 9.75c-.546.546-1.457.637-2.095 0z"
                    transform="translate(-822 -834) translate(108 592) translate(369 238) translate(334 4) translate(11.5)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
