import React, { useState, useEffect } from 'react';
import {ImageRecorder} from './ImageRecorder';
// import {VideoRecorder as ImageRecorder} from './VideoRecorder';
import {StickerSelect} from './AddBgAndStickers';
import {EXPERIENCE} from './../../experience/Stories';
import {TextArea} from './TextArea';
import {Input} from './Input';
import defaultUser from './../../assets/impact21/images/chat-bubble.PNG'
const LIMIT = 280;
const topic = 'impact21';

let image = '';
let hashtags = ['MyIMPACT'];
let hashtagSuggestions = ['FutureReady','CiscoIMPACT', 'InclusiveFuture', 'EmpoweredbyCisco'];
let story = 'My Cisco IMPACT story';
// let user = {};
// let userInfo = {};
// let oktaToken = '';
let width=''
let height='';

const resetData = () => {
  hashtags = ['MyIMPACT'];
  story = 'My Cisco IMPACT story';
  width='';
  height='';
}

export function ImageCapture(props) {
  function getImage(imgBlob, videoConstraints) {
    image = imgBlob;
    width = videoConstraints.width;
    height = videoConstraints.height;
    // console.log('image blob', img)
    props.setExperience('PREVIEW_IMAGE');
  }
  // useEffect(() => {
  //   navigator.getUserMedia (
  //     // constraints
  //     {
  //        video: true,
  //        audio: false
  //     },
   
  //     // successCallback
  //     function(localMediaStream) {
  //       //  var video = document.querySelector('video');
  //       //  video.src = window.URL.createObjectURL(localMediaStream);
  //       //  video.onloadedmetadata = function(e) {
  //       //     // Do something with the video here.
  //       //  };
  //       setRenderCamera(true);
  //       setPermission(true);
  //     },
   
  //     // errorCallback
  //     function(err) {
  //      if(err) {
  //        setPermission(false);
  //        setRenderCamera(false);
  //        // Explain why you need permission and how to update the permission setting
  //      }
  //     }
  //  );
  // })
  return (
    <div className='container image-capture'>
      <div className='top-left-logo'></div>
      <div className='image-capture-container'>
        <div className='sub-heading'>Capture a Photo</div>
        <div className='heading'>Photo</div>
        <div className='image-live-preview'>
          {<ImageRecorder type='Camera' cb={getImage}>
            <div className='capture-action'>
              <i className='icon-fk icon-fk-camera'></i>
              <div className='image camera'></div>
              <span className='text'>Capture</span>
            </div>
            <div className='info'>
        <div className='instructions'>Use a light background to optimize your photo.</div>
      </div>
          </ImageRecorder>}
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience('default')}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function ImagePreview(props) {
  const [hide, setHide] = useState(false);
  function setExperience(val) {
    props.setExperience(val);
  }

  function getImage() {
    hideControls();
    setTimeout(() => {
      const canvas2 = document.getElementById('fabricCanvas');
    image = canvas2.toDataURL();
    return props.setExperience(EXPERIENCE.ADD_IMAGE_POST);
    }, 1000)
    
  }
  const hideControls = () => {
    // console.log('hide set to true');
    setHide(true);
  }


  return (
    <div className='container image-preview'>
      <div className='top-left-logo'></div>
      <div className='image-preview-container'>
        <div className='sub-heading'>Capture a Photo</div>
        <div className='heading'>Stickers and Backgrounds</div>
        <div className='image-preview'>
          <img src={image} alt='preview' />
          <StickerSelect photo={image} width={width} height={height} hideControls={hide}/>
        </div>
        <div className='action'>
          <div className='redo' onClick={() => setExperience(EXPERIENCE.CAPTURE_IMAGE)}>
            <i className='icon-fk icon-fk-camera'></i>
            <div className='image camera'></div>
            <span className='text'>Retake Photo</span>
          </div>
          <div className='continue' onClick={getImage}><span className='text'>Continue</span></div>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function AddImagePost(props) {
  // console.log('props', props);
  const [limit, setLimit] = useState(LIMIT);
  const [hashtag, setHashtags] = useState(hashtags);
  const [firstLanding, setFirstLanding] = useState(true);
  function getText(value) {
    // console.log('limit', value.length);
    if(value.length > LIMIT) {
      return
    }
    story = value;
    // console.log('value', value, value.length)
    setLimit(LIMIT - value.length);
  }
  function resetHashtags(arr) {
    hashtags=arr;
    setHashtags(arr)
  }
  function removeHashtag(tag) {
    // console.log('state', hashtag)
    let newHashtags = hashtag.filter(e => e!==tag);

    resetHashtags(newHashtags);
  }
  function getHashtagText(value) {
    addHashtags(value);
  }
  function goToReview() {
    // console.log('lmi', limit)
    setFirstLanding(false);
    if(limit < LIMIT) {
      props.setExperience(EXPERIENCE.IMAGE_POST_REVIEW)
    }
  }
  function addHashtags(tag) {
    let newHashtag = [...hashtag];
    // console.log(newHashtag.length, typeof newHashtag);
    if(newHashtag.length >= 4) {
      return;
    }
    newHashtag.push(tag)
    hashtags = newHashtag;
    setHashtags(newHashtag)
  }

  useEffect(() => {
    if(story) {
      setLimit(LIMIT - story.length)
    }
  }, [])
  // console.log('story', story);
  // console.log('hashtags', hashtags)
  return (
    <div className='container full-image'>
      <div className='top-left-logo'></div>
      <div className='full-image-container'>
        <div className='sub-heading'>Capture a Photo</div>
        <div className='heading'>Create Your Shared Story</div>
        <div className='add-post-container'>
          <div className='image-preview-and-retake'>
            <img src={image} alt='preview' />
            <div className='action-retake'>
              <div onClick = {() => props.setExperience(EXPERIENCE.CAPTURE_IMAGE)} className='btn-redo'>
                <i className='icon-fk icon-fk-camera' />
                <div className='image camera'></div>
                <span className='text'>Retake Photo</span>
                </div>
            </div>
          </div>
          <div className='add-story-container'>
            <div className='user-info'>
              <img src={defaultUser} alt="user"/>
              <div className='username'>
                <span className='firstname'>{props.userInfo.first_name}</span>
                <span className='lastname'>{props.userInfo.last_name}</span>
              </div>
            </div>
            <div className='story-container'>
              <div className='heading'>Shared Story</div>
              <div className='sub-heading'>Add caption with up to 280 characters</div>
              <div className={`text-area ${(limit === LIMIT) && !firstLanding ? 'error': ''}`}>
                <TextArea 
                  placeholder="My Cisco IMPACT story  [ Shared Story Caption ]"
                  value={story} 
                  getText={getText} 
                  limit={LIMIT}
                  >
                </TextArea>
                <div className='text-area-meta'>
                  <span className='error-msg'>You must enter a story to continue</span>
                  <span className='character-count'>{limit}</span>
                </div>
              </div>
            </div>
            <div className='hashtag-container'>
              <div className='heading'>Hashtags</div>
              <div className='sub-heading'>Type a Hashtag or Select a Suggested Hashtag (Up to 4 total)</div>
              <div className={`hashtags ${hashtags.length >=4 ? 'hideinput': ''}`}>
                {hashtags.map((hash, i) => 
                  <div className='hashtag-wrapper' key={hash}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    {i === 0 ? null : <i className='icon-fk icon-fk-cancel-circle' onClick={() => removeHashtag(hash)}></i> }
                  </div>)}
                <Input placeholder="Enter a #hashtag" getText={getHashtagText}></Input>
              </div>
              <div className='hashtags-suggestion'>
                {hashtagSuggestions.filter(e => !hashtags.includes(e)).map((hash) => (
                  <div key={hash} className='hashtag-wrapper' onClick={() => addHashtags(hash)}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    <i className='icon-fk icon-fk-plus'></i>
                    <div className='image icon-fk-add'></div>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
        </div>
        <div className='action-container full-image'>
          <div className='back' onClick={() => props.setExperience(EXPERIENCE.PREVIEW_IMAGE)}>
            <i className='icon-fk icon-fk-left-arrow'></i>
            <div className='image back-icon-fk'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={goToReview}>
            <span className='text'>Preview Story</span>
          </div>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function ImagePostReview(props) {
  const [publish, setPublishAction] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  function setExperience(val) {
    props.setExperience(val);
  }

  function publishPost() {
    setPublishAction(true)
    setApiLoading(true);
  }


  useEffect(() => {
    if(publish) {
      // console.log('make api call');
      // console.log('take use to download page');
      // const blob = dataURLtoBlob(image);
      fetch(image).then(res => res.blob()).then(blob => {
        const formData = new FormData();
        formData.append("type", "image");
        formData.append("media", blob);
        formData.append("story", story);
        formData.append("hashtags", JSON.stringify(hashtags));
        formData.append("author", JSON.stringify({name: props.userInfo.full_name, email: props.userInfo.email_address}));
        formData.append("width", width);
        formData.append("height", height);
        fetch(`https://api.fankave.com/cmsa/stories/${topic}/publish?x-access-token=${props.token}`, {
          method: 'POST',
          body: formData
        }).then(e => {
          console.log('published successfully', e);
          setExperience(EXPERIENCE.DOWNLOAD_IMAGE)
        }).catch(err => {
          console.error("Error: ", err);
          setExperience(EXPERIENCE.DOWNLOAD_IMAGE)
        })
      });
    }
  })
  return (
    <div className='outer-container'>
      <div className='container image-review'>
      <div className='heading-out'>Preview Story</div>
       <div className='top-left-logo'></div>
       <div className={`spinner ${apiLoading ? '' : 'hide'}`}></div>
       <div className='image-post-preview-container'>
         <div className='image-preview'>
           <img src={image} alt='preview' />
         </div>
         <div className='story'>
           {story}
         </div>
        <div className='hashtags'>
          {hashtags.map((hash, i) => 
            <div className='hashtag-wrapper' key={hash}>
              <div className='hashtag'>{`#${hash}`}</div>
            </div>)}
        </div>
        <div className='user-info'>
          <img src={defaultUser} alt="preview"/>
          <div className='username'>
            <span className='firstname'>{props.userInfo.first_name}</span>
            <span className='lastname'>{props.userInfo.last_name}</span>
          </div>
        </div>
      </div>
      <div className='action'>
        <div className='back' onClick={() => setExperience(EXPERIENCE.ADD_IMAGE_POST)}>
          <i className='icon-fk icon-fk-left-arrow'></i>
          <div className='image back-icon-fk'></div>
          <span className='text'>Back</span>
        </div>
        <div className='continue' onClick={publishPost}>
          <span className='text'>Publish Story</span>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      </div>
      <div className='footer'></div>
    </div>
  )
}

export function ImageDownload(props) {
  function setExperience(val) {
    resetData();
    props.setExperience(val);
  }

  useEffect(() => {}
  )
  return (
    <div className='container image-download'>
      <div className='top-left-logo'></div>
      <div className='title'>
      Shared Story Posted
      </div>
      <div className='tagline'>
      Great news, your story is out there for all to see! Head over to the Shared Stories gallery to see how it looks and explore other stories.  Or, if you have more to say, create another one.</div>
      <div className='image-download-container'>
        <div className='image-download-preview'>
          <img src={image} alt='preview' />
          <div className='filename'>Photo.png</div>
          <a href={image} download="image.png">
          <div className='download'>
            <i className='icon-fk icon-fk-download'/>
            <div className='dwnld-img' download="image.png" href={image}>
              <div className='image icdownload'></div>
              Download
            </div>
          </div>
          </a>
        </div>
        <div className='action'>
          <div className='close' onClick={() => window.close()}>{`Close & View Stories`}</div>
          <div className='redo' onClick={() => setExperience('')}>Create a New Story</div>
        </div>
      </div>
      <div className='footer'></div>
    </div>
  )
}
