import React, { Component } from "react";

import "./cardv2.css";
import { getEmbedThemeInfo, getThemeInfo } from "../theme/Loader";
// import twitterbg from '../assets/twitterbg.png';
import defaultUser from '../assets/default.jpg';
import { VideoPlayer } from "./../component/ImpactGallery/VideoPlayer/VideoPlayer";
import _ from 'lodash';

export default class Cardv2 extends Component {
  authorName() {
    let { post } = this.props;
    return <div className="name">{post.getAuthorName()}</div>;
  }

  truncate = (s = "", portrait = false) => {
    // return s;
    let charLimit = getEmbedThemeInfo().char_limit || 90;
    // console.log('charlimit', charLimit, portrait)
    let charLimitPortrait = getThemeInfo().char_limit_portrait || 50;
    if (portrait) {
      if (s.length <= charLimitPortrait) {
        return s;
      }
      return s.slice(0, charLimitPortrait).concat("...");
    }
    if (s.length <= charLimit) {
      return s;
    } else {
      return s.slice(0, charLimit).concat("...");
    }
  };

  getLikes() {
    let { post } = this.props;
    // console.log(post, post.getLikes())
    if (post.getLikes() > 0) {
      return (
        <div>
          <div className='heart'></div>
          <i className="like-icon-fk icon-fk-heart-empty" />
          <div className="like-count">{post.getLikes()}</div>
        </div>
      );
    }
    return <i className="like-icon-fk icon-fk-heart-empty" />;
  }
  getSource() {
    let { post } = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
      return (
        <span className="src-twitter">
          <i className="icon-fk-twitter-1" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === "instagram") {
      return (
        <span className="src-instagram">
          <i className="icon-fk-instagram-1" />
        </span>
      );
    }
    return null;
  }

  getRetweets() {
    let { post } = this.props;
    if (post.getRetweets() > 0) {
      return (
        <div>
          <div className='retweet'></div>
          <i className="retweet-icon-fk icon-fk-retweet" />
          <div className="retweet-count">{post.getRetweets()}</div>
        </div>
      );
    }
    return <i className="retweet-icon-fk icon-fk-retweet" />;
  }

  renderImage(post) {
    // console.log('post', post);
    const url = _.get(post.embed, 'media[0].url')
    const poster = _.get(post.embed, 'media[0].thumbUrl')
    // console.log('---url', url);
    if (post.isVideoMedia()) {
      return <VideoPlayer url={url} poster={poster} />
    } else {
      return null;
    }
  }

  openTweet = () => {
    let { post } = this.props;
    window.open(post.getUrl(), "_blank");
  }

  render() {
    let { post, portrait } = this.props;
    return (
      <div className={`cardv2 ${portrait ? "portrait" : ""}`}>

        <div
          className="image"
          style={{ backgroundImage: `url(${post.getMediaUrl()})` }}
        >
          {this.renderImage(post)}
          <div className="source">{this.getSource()}</div>
        </div>

        <div className="details" onClick={this.openTweet}>
          <div className="author">
            <img className="avatar" src={post.getAuthorPhoto() || defaultUser} alt="" />
            {this.authorName()}
            <div className="handle">{post.getAuthorHandle()}</div>
            <span className="created-at">{post.getCreatedAt()}</span>
          </div>
          <div className="msg">
            {this.truncate(post.getText(), post.isPortrait())}
          </div>
        </div>
        <div className="stats">
          <a
            href={`${post.getUrl()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="reply-icon-fk icon-fk-reply" />
          </a>
          {this.getRetweets()}
          {this.getLikes()}
        </div>
      </div>
    );
  }
}
