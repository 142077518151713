import React, { useState } from "react";

import { Filter } from "../Filter/Filter";

import "./filtersDropdown.css";

export const FiltersDropdown = ({
  selectedFilters,
  onFiltersConfirm,
  isMobile = false,
}) => {
  const hashtagsList = [
    "#CiscoIMPACT",
    "#EmpoweredbyCisco",
    "#FutureReady",
    "#InclusiveFuture",
  ];
  const geoList = ["America", "EMEA", "JPJC"];
  const sortList = ["Latest", "Rank"];
  const [filters, setFilters] = useState(selectedFilters);

  const handleFiltersChange = (currentFilters, type) => {
    setFilters({
      ...filters,
      [type]: currentFilters,
    });
  };

  const handleReset = () => {
    setFilters({
      sort: ["Latest"],
    });
  };

  const handleConfirm = () => {
    onFiltersConfirm(filters);
  };

  return (
    <div
      className={`fk-filters-dropdown-wrapper ${
        isMobile ? "fk-filters-dropdown-wrapper-mobile" : null
      }`}
    >
      <div className="fk-filters-dropdown">
        <div className="fk-filters-reset" onClick={handleReset}>
          Reset
        </div>
        <div className="fk-filters-dropdown-filter">
          <p className="fk-filter-label">Hashtags:</p>
          <Filter
            isMobile
            list={hashtagsList}
            type="multi"
            selected={(filters["hashtag"] || []).map(
              (currentValue) => `#${currentValue}`
            )}
            onChange={(selectedFilters) => {
              return handleFiltersChange(
                selectedFilters.map((currentValue) =>
                  currentValue.substring(1)
                ),
                "hashtag"
              );
            }}
          />
        </div>
        <div className="fk-filters-dropdown-filter">
          <p className="fk-filter-label">Geo:</p>
          <Filter
            isMobile
            list={geoList}
            type="multi"
            selected={filters["geo"]}
            onChange={(selectedFilters) =>
              handleFiltersChange(selectedFilters, "geo")
            }
          />
        </div>
        <div className="fk-filters-dropdown-filter fk-filters-dropdown-filter-border">
          <p className="fk-filter-label">Sort:</p>
          <Filter
            isMobile
            list={sortList}
            type="radio"
            selected={filters["sort"]}
            onChange={(selectedFilters) =>
              handleFiltersChange(selectedFilters, "sort")
            }
          />
        </div>

        <div className="fk-filters-confirm-btn">
          <div className="fk-filters-confirm" onClick={handleConfirm}>
            Show Results
          </div>
        </div>
      </div>
    </div>
  );
};
