import { useEffect, useState } from 'react'
import { OktaAuth } from '@okta/okta-auth-js'

import { getRemainingTime } from '../utils'
import Config from './../Config'

const EVENT_TYPE = 'onImpactStorageChange'

const dispatch = (eventType) => {
  window.dispatchEvent(new CustomEvent(eventType))
}

const setToken = (token) => {
  sessionStorage.setItem('fk-impact-okta-auth', token)
  return dispatch(EVENT_TYPE)
}

const removeToken = () => {
  sessionStorage.removeItem('fk-impact-okta-auth')
  return dispatch(EVENT_TYPE)
}

const REACT_APP_ENV = process.env.REACT_APP_ENV || 'local'
const appConfig = Config.api[REACT_APP_ENV]

const OktaConfig = {
  base_url: appConfig.OKTA_URL,
  client_id: appConfig.OKTA_CLIENT_ID,
  redirectUri: appConfig.OKTA_REDIRECT_URI,
}

const params = {
  // Required config
  issuer: `${OktaConfig.base_url}/oauth2/default`,
  clientId: `${OktaConfig.client_id}`,
  redirectUri: `${OktaConfig.redirectUri}`,
  // Use authorization_code flow
  responseType: 'code',
  scope: 'openid offline_access email',
  pkce: true,
}

// console.log('fankave okta config', params)

export const useOktaAuth = () => {
  const authClient = new OktaAuth(params)
  const [sessionToken, setSessionToken] = useState(null)
  const [sessionData, setSessionData] = useState(null)
  const [schedulerId, setSchedulerId] = useState(null)
  const [isAuthenticating, setIsAuthenticating] = useState(false)

  const generateToken = () => {
    setIsAuthenticating(true)
    authClient.token
      .getWithoutPrompt({
        responseType: 'id_token', // or array of types
        sessionToken: sessionToken, // optional if the user has an existing Okta session
      })
      .then(function (res) {
        const tokens = res.tokens
        const { accessToken, expiresAt } = tokens.accessToken
        // Do something with tokens, such as
        // authClient.tokenManager.setTokens(tokens)
        setSessionData(res)
        setSessionToken(tokens.accessToken)
        setToken(accessToken)
        const timeoutID = setTimeout(
          refreshToken,
          (getRemainingTime(expiresAt) - 300) * 1000
        )
        setSchedulerId(timeoutID)
        setIsAuthenticating(false)
      })
      .catch(function (err) {
        console.log('err', err)
        setIsAuthenticating(false)
        resetToken()
      })
  }

  const refreshToken = () => {
    setIsAuthenticating(true)
    authClient.token
      .renew({ sessionToken, scopes: ['openid', 'email'] }) // RefreshToken
      .then(function (res) {
        const { accessToken, expiresAt } = res.accessToken
        setSessionData(res)
        setSessionToken(res.accessToken)
        setToken(accessToken)
        clearTimeout(schedulerId)
        const timeoutID = setTimeout(
          refreshToken,
          (getRemainingTime(expiresAt) - 300) * 1000
        )
        setSchedulerId(timeoutID)
        setIsAuthenticating(false)
      })
      .catch(function (err) {
        console.log('err', err)
        setIsAuthenticating(false)
        resetToken()
      })
  }

  const resetToken = () => {
    if (sessionToken) {
      authClient.token.revoke(sessionToken)
      setSessionToken(null)
      setSessionData(null)
    }
    removeToken()
    if (schedulerId) {
      clearTimeout(schedulerId)
    }
  }

  useEffect(() => {
    generateToken()
    window.fkImpactOktaAuth = {
      logout: resetToken,
    }
    return () => {
      resetToken()
      window.fkImpactOktaAuth = {
        logout: removeToken,
      }
    }
    // eslint-disable-next-line
  }, [])

  return [
    {
      isAuthenticated: !!sessionToken,
      sessionToken,
      sessionData,
      isAuthenticating,
    },
    refreshToken,
    resetToken,
  ]
}
