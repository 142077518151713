import qs from 'qs';
import _ from 'lodash';

import settings from './settings.json';
import i18n from './i18n.json';

function queryString(queryStringDefaults = {}) {
  let location = window.location.href.split("?")[1] || "";
  location = location.replace(/\?/, '');
  let queryStringParsed = qs.parse(location);
  return _.merge(queryStringDefaults, queryStringParsed);
}

function getDefaults() {
  return settings['default'];
}

export const getThemeName = () => {
  return queryString().theme || 'default';
}

export const getCategory = () => {
  return queryString().category || null;
}

export const getThemeInfo = () => {
  return settings[getThemeName()];
}

export const getCharLimit = () => {
  return getEmbedThemeInfo().char_limit || getDefaults().char_limit;
}

export const getLanguage = () => {
  return queryString().lang || 'en';
}
export const getExperienceText = (exp, key) => {
  const theme = getThemeName();
  const lang = getLanguage();
  return i18n[theme][lang][exp][key];
}

export const getEmbedThemeName = () => {
  return window.fkEmbedConfig.theme || 'default';
}

export const getEmbedCategory = () => {
  return window.fkEmbedConfig.category || null;
}

export const getEmbedThemeInfo = () => {
  return settings[getEmbedThemeName()];
}

export const getEmbedCharLimit = () => {
  return getEmbedThemeInfo().char_limit || getDefaults().char_limit;
}

export const getEmbedLanguage = () => {
  return window.fkEmbedConfig.lang || 'en';
}
export const getEmbedExperienceText = (exp, key) => {
  // console.log('---exp', exp, '---key', key);
  const theme = getEmbedThemeName();
  const lang = getEmbedLanguage();
  // console.log('--theme---', theme)
  // console.log('--lang---', lang)
  // console.log(i18n[theme][lang]);
  return i18n[theme][lang][exp][key];
}
