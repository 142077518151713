const dayjs = require('dayjs')

export function getDateDiff(date) {
  try {
    let today = dayjs()
    let postDate = dayjs(date)
    let dateDiff = today.diff(postDate, 'day')
    if (dateDiff < 1) {
      return 'today'
    }
    if (dateDiff === 1) {
      return `yesterday`
    }
    if (dateDiff <= 6) {
      return `${dateDiff} days ago`
    }
    if (dateDiff <= 30) {
      let diff = today.diff(postDate, 'week')
      if (diff === 1) {
        return `${diff} week ago`
      }
      return `${diff} weeks ago`
    }
    if (dateDiff < 365) {
      let diff = today.diff(postDate, 'month')
      if (diff === 1) {
        return `${diff} month ago`
      }
      return `${diff} months ago`
    }
  } catch (err) {
    return dayjs(date).format('DD MMM')
  }
}

export const handleError = (error) => {
  console.error('Error fetching posts', error)
}

export const findHashtags = (searchText) => {
  const regexp = /\B\#\w\w+\b/g // eslint-disable-line
  const result = searchText.match(regexp)
  if (result) {
    return result.map((str) => str.replace('#', ''))
  } else {
    return false
  }
}

export const triggerSAEvent = (string) => {
  if (window.sa_event) {
    let url = ''
    try {
      url = window.location.href.split('?')[0]
    } catch (err) {}
    // console.log(`${url}: action: ${string}`)
    window.sa_event(`${url}: action: ${string}`)
  }
}

export const getRemainingTime = (timestamp) => {
  return timestamp - dayjs().unix()
}

export const waitForAddedNode = (params) => {
  const config = {
    subtree: !!params.recursive || !params.parent,
    childList: true,
  }
  const parent = params.parent || document
  const el = document.getElementById(params.id)
  const creationObserver = new MutationObserver(function () {
    const target = document.getElementById(params.id)
    if (target) {
      this.disconnect()
      params.done(target)
      removalObserver.observe(parent, config)
    }
  })
  const removalObserver = new MutationObserver(function () {
    if (!document.getElementById(params.id)) {
      this.disconnect()
      creationObserver.observe(parent, config)
    }
  })
  if (el) {
    params.done(el)
    removalObserver.observe(parent, config)
  }
  if (!el) {
    creationObserver.observe(parent, config)
  }
}

export const convertSecondsToHourMinute = (milliSeconds) => {
  const sec_num = parseInt(milliSeconds, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  const formattedTime = [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .join(":");
  return formattedTime;
};

