import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { Loader } from "../Loader/Loader";
// import { getImageSize } from "../utils";
import ChatBubble from "../../../assets/impact21/images/chat-bubble.PNG";
import "./story.css";

const VideoViewer = ({ thumbUrl, url, autoPlay, style }) => {
  return (
    <VideoPlayer
      thumbUrl={thumbUrl}
      url={url}
      autoPlay={autoPlay}
      style={style}
    />
  );
};

const ImageViewer = ({ urls = [] }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider className="fk-image-slider" {...settings}>
      {urls.map(({ url }, index) => (
        <img
          key={`image-${index}`}
          className="fk-image"
          src={url}
          alt=""
          crossOrigin="anonymous"
        />
      ))}
    </Slider>
  );
};

const HeartIcon = () => (
  <svg title="SVG Heart Icon" viewBox="-11 -14 122 130" width="25" height="24">
    <path
      d="
		 M 50 0,
		 A 1 1 0 1 0 0 50, 
		 L50,100, 
		 L100,50,
		 A 1 1 0 1 0 50 0"
      fill="#ef3232"
    />
  </svg>
);

export const Story = ({
  story,
  texture = null,
  isLoading = false,
  width = "100%",
  height = "100%",
  autoPlay = false,
  onLikeClick = () => {},
}) => {
  const isImage = (story.getMediaType() || "").includes("image");
  const tags = story.getHashtags() || ["myFY21Impact"];
  // const imageSize = story.getMediaSize();
  const likes = story.getLikes();
  const author = story.getAuthorName();
  const storyText = story.getText().slice(0, 280);
  const hashtags = tags.map((tag) => `#${tag} `);
  const { isLiked = false } = story.getUserMetrics() || {};
  const imageRef = useRef(null);
  // const [adjustedSize, setAdjustedSize] = useState(
  //   getImageSize(
  //     { width: imageSize.w || 300, height: imageSize.h || 400 },
  //     { width: 300, height: isImage ? 420 : 380 }
  //   )
  // );

  // useEffect(() => {
  //   setAdjustedSize(
  //     getImageSize(
  //       { width: imageSize.w || 300, height: imageSize.h || 400 },
  //       {
  //         width: imageRef.current?.clientWidth - 40 || 300,
  //         height: isImage ? 420 : 380,
  //       }
  //     )
  //   );
  // }, [imageSize, isImage]);

  return (
    <div
      className="fk-post-container"
      id="post"
      style={{ width, maxHeight: height }}
      ref={imageRef}
      onClick={(e) => e.stopPropagation()}
    >
      {isLoading && <Loader />}
      {isImage ? (
        <div className="fk-post-image" style={{}}>
          <ImageViewer urls={story.getMediaUrls()} />
        </div>
      ) : (
        <div className="fk-post-video" style={{}}>
          <VideoViewer
            url={story.getVideoUrl()}
            thumbUrl={story.getMediaUrl()}
            autoPlay={autoPlay}
          />
        </div>
      )}
      <div className="fk-post-content">
        <div className="fk-text">{storyText}</div>
        <div className="fk-tags">{hashtags}</div>
        <div className="fk-user-details">
          <div className="fk-user-image">
            <img src={ChatBubble} className="fk-image" alt="" />
          </div>
          <div className="fk-user-name">{author || "Anonymous User"}</div>
          <div className="fk-user-likes" onClick={onLikeClick}>
            <div className={isLiked ? "fk-liked-heart-icon" : "fk-heart-icon"}>
              <HeartIcon />
            </div>
            <div className="fk-likes-count">{likes}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
