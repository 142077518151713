import React, { useState, useEffect, useReducer, useRef } from "react";
import _ from "lodash";

import { Header } from "./Header/Header";
import { GalleryView } from "./GalleryView";
import { Story } from "./Story/Story";
import { Overlay } from "./Overlay/Overlay";
import { Loader } from "./Loader/Loader";
import { NoContent } from "./BlankScreens/NoContent";

import {
  getImpactGallery,
  getImpactUserMetrics,
  updateUserMetrics,
} from "../../Services";
import { getEmbedThemeInfo } from "../../theme/Loader";

import "./impactGallery.css";
import { makeFilters, transformData } from "./utils";

let rawStories = [],
  startStory = null,
  endStory = null,
  rc = false;

const reducer = (state, newState) => {
  return { ...state, ...newState };
};

export default ({ width, height, refreshToken = () => {} }) => {
  const themeConfig = getEmbedThemeInfo();
  const headerRef = useRef(null);
  const galleryRef = useRef({});

  const initialState = {
    apiLimit: _.get(themeConfig, "gallery.apiLimit") || 60,
    stories: [],
    visited: localStorage.getItem("visited"),
    filters: {
      sort: ["Latest"],
    },
  };

  const [{ apiLimit, stories, visited, filters }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const [isLoading, setLoading] = useState(false);
  const [isSelectedLoading, setSelectedLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const getStories = (params) => {
    setLoading(true);
    return getImpactGallery(
      (posts) => {
        rc = posts.length > apiLimit - 1;
        const data = transformData(posts);
        if (!data.length) {
          setLoading(false);
        }

        rawStories = posts.reverse();
        startStory = _.get(posts, `[${posts.length - 1}].id`);
        endStory = _.get(posts, "[0].id");
        dispatch({
          stories: data.reverse(),
        });
      },
      (error) => {
        if (error.name === "UnauthorizedError") {
          refreshToken();
        }
        setLoading(false);
      },
      params
    );
  };

  const paginateStories = (type) => {
    const isNewer = type === "newer";
    if (isNewer && headerRef.current) {
      headerRef.current.getSearchOptions();
    }
    return new Promise((resolve) => {
      const queryParams = {
        temporality: type,
        id: isNewer ? startStory : endStory,
        limit: 30,
        ...makeFilters(filters),
        ...(!isNewer && rc ? { rc } : {}),
      };
      return getImpactGallery(
        (posts) => {
          const data = transformData(posts);
          const removalSize =
            rawStories.length + posts.length > apiLimit
              ? rawStories.length + posts.length - apiLimit
              : 0;
          const items = isNewer
            ? [...rawStories.splice(removalSize), ...posts]
            : [
                ...posts.reverse(),
                ...rawStories.splice(0, apiLimit - removalSize),
              ];

          rawStories = items;
          startStory = _.get(items, `[${items.length - 1}].id`);
          endStory = _.get(items, "[0].id");
          resolve(data);
        },
        (error) => {
          if (error.name === "UnauthorizedError") {
            refreshToken();
          }
          resolve([]);
        },
        queryParams
      );
    });
  };

  useEffect(() => {
    getStories({});
    // eslint-disable-next-line
  }, []);

  const handleAppliedFilter = (selectedFilters) => {
    const { sort } = filters;
    dispatch({
      stories: [],
      filters: { sort, ...selectedFilters },
    });
    getStories(makeFilters({ sort, ...selectedFilters }));
  };

  const handleOverlayAction = () => {
    localStorage.setItem("visited", true);
    dispatch({ visited: true });
  };

  const handleStoryClick = (currentItem) => {
    const { data: story } = currentItem || {};
    if (story) {
      if (!story.getUserMetrics()) {
        setSelectedLoading(true);
        const postId = story.getId();
        getImpactUserMetrics(
          (results) => {
            setSelectedLoading(false);
            story.setUserMetrics({
              isLiked: false,
            });
          },
          (error) => {
            if (error.name === "UnauthorizedError") {
              refreshToken();
            }
            setSelectedLoading(false);
          },
          makeFilters({
            pid: [postId],
          })
        );
      }
    }
    setSelectedItem(currentItem);
  };

  const handleLike = () => {
    if (selectedItem) {
      const { data: story, textureId } = selectedItem || {};
      const { isLiked = false } = story.getUserMetrics() || {};
      const metrics = story.getMetrics() || {};
      const likes = metrics.likes + (isLiked ? -1 : 1);
      const updateMetics = () => {
        story.setUserMetrics({
          ...story.getUserMetrics(),
          isLiked: !isLiked,
        });
        story.setMetrics({
          ...metrics,
          likes,
        });
        setSelectedItem({ ...selectedItem, data: story });
        galleryRef.current.updateTexture(textureId, story);
      };
      updateUserMetrics(
        () => {
          updateMetics();
        },
        (error) => {
          if (error.name === "UnauthorizedError") {
            refreshToken();
          }
          updateMetics();
        },
        { pid: story.getId(), type: isLiked ? "unlike" : "like" }
      );
    }
  };

  return (
    <div className="fk-gallery-view-container" style={{ width, height }}>
      {isLoading && <Loader />}
      <Header
        ref={headerRef}
        selectedFilters={filters}
        onFilterApplied={handleAppliedFilter}
        refreshToken={refreshToken}
      />
      {selectedItem && (
        <div
          className="fk-story-container"
          onClick={() => handleStoryClick(null)}
        >
          <div className="fk-story-inner-container">
            <Story
              isLoading={isSelectedLoading}
              story={selectedItem.data}
              texture={selectedItem.texture}
              width={400}
              height={height - 40}
              onLikeClick={handleLike}
              autoPlay
            />
          </div>
        </div>
      )}
      {stories.length ? (
        <GalleryView
          data={stories}
          rawStories={rawStories}
          width={width - 4}
          height={height - 140}
          onStoryClick={handleStoryClick}
          setLoading={setLoading}
          onPaginate={paginateStories}
          ref={galleryRef}
        />
      ) : isLoading ? (
        <></>
      ) : (
        <NoContent />
      )}
      {!visited && <Overlay onAction={handleOverlayAction} />}
    </div>
  );
};
