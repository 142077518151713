import * as THREE from "three";

import PlayButton from "../../../assets/impact21/images/play-button.svg";

export const getImageSize = (imageSize, containerSize) => {
  if (imageSize.width > imageSize.height) {
    return {
      width: containerSize.width,
      height: (imageSize.height * containerSize.width) / imageSize.width,
    };
  }
  if (imageSize.width < imageSize.height) {
    return {
      width: (imageSize.width * containerSize.height) / imageSize.height,
      height: containerSize.height,
    };
  }
  return {
    width: containerSize.width,
    height: containerSize.width,
  };
};

export const getSize = (imageSize, containerSize) => {
  const childRatio = imageSize.width / imageSize.height;
  const parentRatio = containerSize.width / containerSize.height;
  let width = containerSize.width;
  let height = containerSize.height;
  if (childRatio < parentRatio) {
    height = width / childRatio;
  } else {
    width = height * childRatio;
  }
  return {
    width,
    height,
  };
};

export const rotatePoint = (x, y, cx, cy, angle) => {
  const s = Math.sin(degsToRads(angle));
  const c = Math.cos(degsToRads(angle));

  // translate point back to origin:
  const oX = x - cx;
  const oY = y - cy;

  // rotate point
  const xNew = oX * c - oY * s;
  const yNew = oX * s + oY * c;

  return { x: xNew + cx, y: yNew + cy };
};

export const degsToRads = (deg) => (deg * Math.PI) / 180.0;

export const roundedBorders = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
};

const roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
  if (typeof radius === "undefined") {
    radius = 5;
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};
export const generatePlayButton = (ctx, width, height, resolve) => {
  const x = width / 2;
  const y = height / 2;
  ctx.fillStyle = "transparent";
  const size = width * 0.3;
  const image = new Image();
  image.crossOrigin = "anonymous";
  image.onload = () => {
    ctx.drawImage(image, x - size / 2, y - size / 2, size, size);
    resolve();
  };
  image.onerror = () => {
    resolve();
  };
  image.src = PlayButton;
};

export const generateCanvasImage = (url, type, config, userName, likes) => {
  return new Promise((resolve) => {
    const image = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    image.crossOrigin = "anonymous";
    const width = config.post.size.width;
    const height = config.post.size.height;
    image.onload = () => {
      canvas.width = width + 40; // 70, 40
      canvas.height = height + 60; // 90, 60

      const imageSize = getSize(
        { width: image.width, height: image.height },
        { width, height }
      );
      // const xOffset =canvas.width / 2 - imageSize.width / 2;
      // const yOffset = 0

      // ctx.shadowBlur = 15
      // ctx.shadowColor = 'rgba(13, 39, 77, 0.16)'
      ctx.shadowColor = "transparent";

      ctx.fillStyle = "#ebebeb"; // f5f5f5, ebebeb
      ctx.strokeStyle = "#ebebeb"; // f5f5f5, ebebeb
      ctx.lineWidth = 1;
      roundRect(ctx, 0, 0, canvas.width, canvas.height, 20, true, true); // 15, 15, -30, -30, 0,0,0,0

      ctx.shadowColor = "transparent";

      ctx.font = "12px CiscoSansTT";
      ctx.fillStyle = "#0d274d";
      // ctx.textAlign = 'center'
      ctx.fillText(
        userName.length > 30 ? `${userName.substring(0, 27)}...` : userName,
        15,
        canvas.height - 15, // 30 with shadow, 12 without
        width
      );
      ctx.fillText(
        `${likes}`,
        200,
        canvas.height - 15, // 30 with shadow, 12 without
        width
      );
      ctx.restore();
      ctx.save();

      ctx.shadowBlur = 10;
      ctx.shadowColor = "rgba(0,0,0,0.4)";

      ctx.fillStyle = "white";
      roundRect(ctx, 15, 15, width + 10, height + 10, 10, true); // 30 30 for shadow, 15, 15 without shadow
      ctx.restore();
      roundedBorders(ctx, 20, 20, width, height, 10); // 35 35 for shadow, 20, 20 without shadow
      ctx.clip();
      ctx.drawImage(image, 20, 20, imageSize.width, imageSize.height); // 35 35 for shadow, 20, 20 without shadow
      ctx.restore();
      ctx.save();
      if (type.includes("video")) {
        return generatePlayButton(ctx, canvas.width, canvas.height - 40, () =>
          resolve(canvas)
        );
        // generatePlayButton(ctx, canvas.width, canvas.height - 30)
      }

      return resolve(canvas);
    };
    image.onerror = () => {
      resolve(null);
    };
    image.src = url;
  });
};

export const createImageTexture = (data, renderer, config) => {
  return new Promise((resolve) => {
    generateCanvasImage(
      data.getMediaType().includes("image")
        ? data.getMediaUrl()
        : data.getThumbnailUrl(),
      data.getMediaType(),
      config,
      data.getAuthorName() || "Anonymous",

      data.getLikes() || 0
    )
      .then((canvas) => {
        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = false;
        texture.size = new THREE.Vector2(
          texture.image.width,
          texture.image.height
        );
        renderer.setTexture2D(texture, 0);
        texture.name = `${data.getMediaUrl()}`;
        texture.mediaType = "image";
        texture.generateMipmaps = false;
        texture.minFilter = THREE.LinearFilter;
        resolve({ data, texture });
      })
      .catch(() => resolve(null));
  });
};

export const getCanvasRelativePosition = (event, canvas) => {
  const rect = canvas.getBoundingClientRect();
  return {
    x: ((event.clientX - rect.left) * rect.width) / rect.width,
    y: ((event.clientY - rect.top) * rect.height) / rect.height,
  };
};

export const transformData = (data = []) => {
  return data.filter((item) => item.getMediaUrl());
};

export const makeFilters = (filters) => {
  const filtersList = Object.keys(filters);
  if (filtersList.length) {
    return filtersList.reduce((transformedFilters = {}, currentKey) => {
      const currentFilter = filters?.[currentKey] || [];
      if (currentFilter.length) {
        return {
          ...transformedFilters,
          [currentKey]: currentFilter.join(","),
        };
      }
      return transformedFilters;
    }, {});
  }
  return {};
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
