import qs from "qs";
import lodash from "lodash";

import Config from "./Config.js";
import Post from "./model/Post.js";
import PostFlat from "./model/PostFlat.js";
import { getEmbedThemeInfo } from "./theme/Loader.js";
import { UnauthorizedError } from "./errors";

const REACT_APP_ENV = process.env.REACT_APP_ENV || "local";
const appConfig = Config.api[REACT_APP_ENV];

function get(url, success, failure, options = {}) {
  return fetch(url, options)
    .then((response) => {
      if (response.status === 401) {
        throw new UnauthorizedError("User in not Authorized");
      }
      if (response.status === 204) {
        throw new Error("No more content");
      }
      return response;
    })
    .then((response) =>
      response.text().then((text) => (text ? JSON.parse(text) : []))
    )
    .then(
      (payload) => success(payload),
      (error) => {
        if (failure) {
          failure(error);
        }
      }
    );
}

export const getFeatured = (success, failure, filter, limit) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    contentType: themeConfig.contentType,
    ctag: themeConfig.ctag,
    filter: filter || themeConfig.filter,
    limit,
  });
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  get(
    url,
    (result) => {
      let res = lodash.filter(result, (e) =>
        lodash.get(e, "content.sections[0].embed.media[0].url", null)
      );
      removeBrokenMedia(res, (values) => {
        if (themeConfig.videoOnly) {
          let posts = values
            .map((r) => new Post(r))
            .filter((post) => post.isVideoMedia());
          success(posts);
        } else {
          let posts = values.map((r) => new Post(r)); //.filter(post => post.isVideoMedia());
          success(posts);
        }
      });
    },
    failure
  );
};

export const getPhotos = (success, failure) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    contentType: "photo",
    ctag: themeConfig.ctag,
    filter: "approved",
  });
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  get(
    url,
    (result) => {
      let res = lodash.filter(result, (e) =>
        lodash.get(e, "content.sections[0].embed.media[0].url", null)
      );
      removeBrokenMedia(res, (values) => {
        let posts = values.map((r) => new Post(r));
        success(posts);
      });
    },
    failure
  );
};

export const getAll = (success, failure) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    contentType: themeConfig.contentType,
    ctag: themeConfig.ctag,
  });
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  get(
    url,
    (result) => {
      let res = lodash.filter(result, (e) =>
        lodash.get(e, "content.sections[0].embed.media[0].url", null)
      );
      removeBrokenMedia(res, (values) => {
        let posts = values.map((r) => new Post(r));
        success(posts);
      });
    },
    failure
  );
};

export const getPost = (postId, success, failure) => {
  let url = `${appConfig.CONTENT_URL}social/${postId}`;
  get(
    url,
    (result) => {
      if (result.length === 1) {
        success(new Post(result[0]));
      }
    },
    failure
  );
};

export const getContent = async (contentType = null, filter = "", from) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify(
    {
      contentType: contentType,
      topic: themeConfig.ctag,
      limit: themeConfig.initialCardCount,
      filter: filter,
      from,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  try {
    let result = await fetch(url);
    let jsonResult = await result.json();
    let posts = jsonResult.map((post) => new PostFlat(post));
    return posts;
  } catch (err) {
    console.error("Error fetching result", err);
    return [];
  }
};

export const getContentFlat = async (contentType = null, filter = "", from) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify(
    {
      contentType: contentType,
      topic: themeConfig.ctag,
      limit: themeConfig.initialCardCount,
      filter: filter,
      format: "flat",
      from,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  try {
    let result = await fetch(url);
    let jsonResult = await result.json();
    let posts = jsonResult.map((post) => new PostFlat(post));
    return posts;
  } catch (err) {
    console.error("Error fetching result", err);
    return [];
  }
};

export const getLeaderboard = async (category = null) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    ctag: themeConfig.ctag,
  });
  let url = category
    ? `${appConfig.CMS_X}leaderboard/social?${params}&limit=10&invert=true`
    : `${appConfig.CMS_X}leaderboard/social?${params}&limit=10`;
  try {
    let result = await fetch(url).then((res) => res.json());
    console.log("---", result);
    return result;
  } catch (err) {
    console.error("Error fetching result", err);
    return [];
  }
};

function isNonMediaData(datum) {
  return datum.type === "text";
}

export const removeBrokenMedia = (data, done) => {
  // console.log(data);
  let groupedData = lodash.groupBy(data, (d) => isNonMediaData(d));
  let valid = groupedData.true || [];
  let mediaData = groupedData.false || [];

  let urlPromises = mediaData.map((d) => {
    // console.log(d.content.sections[0].embed.media[0].url)
    let mediaUrl = lodash.get(d, "content.sections[0].embed.media[0].url", "");
    return fetch(mediaUrl, { method: "GET" })
      .then((response) => response)
      .catch((err) => err);
  });

  Promise.all(urlPromises).then((responses) => {
    responses.forEach((response, i) => {
      if (response.status === 200) {
        valid.push(mediaData[i]);
      }
    });
    valid = lodash.orderBy(valid, "createdAt", "desc");
    done(valid);
  });
};

export const getHighlights = (success, failure, params) => {
  let themeConfig = getEmbedThemeInfo();
  let param = qs.stringify(
    { ...params, ctag: themeConfig.ctag },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social?${param}`;
  get(
    url,
    (result) => {
      let nonVideo = lodash.filter(
        result,
        (e) => lodash.get(e, "type", null) !== "video"
      );
      removeBrokenMedia(nonVideo, (values) => {
        let posts = values.map((r) => new PostFlat(r));
        success(posts);
      });
    },
    failure
  );
};

export const getGallery = (success, failure, params) => {
  let themeConfig = getEmbedThemeInfo();
  let param = qs.stringify(
    {
      ...params,
      ctag: themeConfig.galleryctag
        ? themeConfig.galleryctag
        : themeConfig.ctag,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social?${param}`;
  get(
    url,
    (result) => {
      let posts = result
        .filter((e) => e.type === "image" || "video")
        .map((json) => new PostFlat(json));
      success(posts);
      // let nonVideo = lodash.filter(
      //   result,
      //   (e) => lodash.get(e, "type", null) !== "video"
      // );
      // removeBrokenMedia(nonVideo, (values) => {
      //   let posts = values.map((r) => new PostFlat(r));
      //   success(posts);
      // });
    },
    failure
  );
};

export const getImpactGallery = (success, failure, params) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || "";
  let themeConfig = getEmbedThemeInfo();
  let param = qs.stringify(
    {
      "x-access-token": oktaToken,
      topic: themeConfig.ctag,
      limit: themeConfig.gallery.apiLimit,
      category: "approved",
      ...params,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social?${param}`;
  get(
    url,
    (result) => {
      let posts = result ? result.map((json) => new PostFlat(json)) : [];
      success(posts);
    },
    failure
  );
};

export const getImpactSearch = (success, failure) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || "";
  let themeConfig = getEmbedThemeInfo();
  let param = qs.stringify(
    { "x-access-token": oktaToken },
    { skipNulls: true }
  );
  let url = `${appConfig.CMS_X}stories/${themeConfig.ctag}/suggestions?${param}`;
  get(
    url,
    ({ data = {} }) => {
      success(data || {});
    },
    failure
  );
};

export const getImpactUserMetrics = (success, failure, params) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || "";
  let param = qs.stringify(
    {
      "x-access-token": oktaToken,
      uid: "test@user.com",
      ...params,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social/likes?${param}`;
  get(
    url,
    ({ data = {} }) => {
      success(data || {});
    },
    failure
  );
};

export const updateUserMetrics = (success, failure, params) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || "";
  let param = qs.stringify(
    {
      "x-access-token": oktaToken,
    },
    { skipNulls: true }
  );
  const uid = "test@user.com";
  const { pid, type } = params;
  let url = `${appConfig.CONTENT_URL}social/${pid}/likes/${uid}?${param}`;
  get(
    url,
    ({ data = {} }) => {
      success(data || {});
    },
    failure,
    {
      method: type === "like" ? "PUT" : "DELETE",
    }
  );
};
