import React from "react";
import Webcam from "react-webcam";
import { useEffect, useState, useCallback } from "react";

import { isMobile } from "react-device-detect";

const videoWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    // console.log('videoWidth', videoWidth, 'isMobile', isMobile);
export const videoConstraints = {
  width: isMobile
    ? undefined
    : videoWidth > 375
    ? 340
    : videoWidth < 375
    ? 294
    : 294,
  height: isMobile 
    ? undefined 
    : videoWidth > 375 
    ? 450 
    : videoWidth < 375 
    ? 388 
    : 388,
  facingMode: "user",
}

var isFirefox = typeof InstallTrigger !== 'undefined';

export const canvasConstraints = {
  width: videoWidth > 375
    ? 340
    : videoWidth < 375
    ? 294
    : 294,
  height: videoWidth > 375 
    ? 450 
    : videoWidth < 375 
    ? 388 
    : 388,
  facingMode: "user",
}
// console.log('videoConstraints', videoConstraints)

export const ImageRecorder = (props) => {
  let cb = props.cb;
  const webcamRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  let [timer, setTimer] = useState(0)
  let [showTimer, setShowTimer] = useState(false);
  let [error, setError] = useState('');
  // let [showNotification, setShowNotification] = useState(false);


  const showAccessBlocked = useCallback((err) => {
    if(typeof err === "object") {
      setError("Please Allow Camera Permission and Refresh page to continue")
      // props.setError('Please Allow Camera Permission to Continue')
    } else {
      setError("Please Allow Camera Permission and Refresh page to continue");
    }
  }, []);


  function triggerCountDown() {
    if(showTimer) {
      return;
    }
    setTimer(timer+1);
    setShowTimer(true);
  }

  const getSize = (image, container) => {
    const childRatio = image.width / image.height
    const parentRatio = container.width / container.height
    let width = container.width
    let height = container.height
    if (childRatio < parentRatio) {
      height = width / childRatio
    } else {
      width = height * childRatio
    }
    return {
      width,
      height,
    }
  }
  useEffect(() => {
      if(timer > 3 && showTimer) {
        setShowTimer(false);
        // console.log(videoConstraints)
        const imageSrc = webcamRef.current.getScreenshot();
        // console.log('imageSrc', imageSrc);
        const image = new Image();
        image.crossOrigin = 'Anonymous'
        image.src = imageSrc;
        image.onload = function() {
          console.log('in-onload', image.width, image.height);
          // var wrh = image.width / image.height;
          // var newWidth = canvasRef.current.width;
          // var newHeight = newWidth / wrh;
          // if (newHeight > canvasRef.current.height) {
          //   newHeight = canvasRef.current.height;
          //   newWidth = newHeight * wrh;
          // }
          const {width, height} = getSize(image, {width: videoConstraints.width || canvasConstraints.width, height: videoConstraints.height || canvasConstraints.height})

          const ctx = canvasRef.current.getContext('2d');
          // console.log('ctx', ctx);
          // ctx.drawImage(image, 0, 0, videoConstraints.width || canvasConstraints.width, videoConstraints.height || canvasConstraints.height);
          if(isFirefox) {
            ctx.drawImage(image, -130, 0, width, height);
          } else {
            ctx.drawImage(image, 0, 0, width, height);
          }
          const src = canvasRef.current.toDataURL('image/jpeg', 1.0);
        // setTimeout(() => {
          // console.log('src', src);
          cb(src, videoConstraints);
        // }, 3000)
        }
        // console.log(imageSrc)
        // cb(src);
        // console.log('effect if', timer, showTimer);
        // const capture = React.useCallback(
        //   () => {
        //     const imageSrc = webcamRef.current.getScreenshot();
            // console.log(imageSrc);
        //     cb(imageSrc)
        //   },
        //   [webcamRef]
        // );
      } else if(showTimer){
        setTimeout(() => {
        setTimer(timer+1)}, 1000)
        // console.log('EFFECT ELSE IF', timer, showTimer);
      } else {
        // console.log('waiting..');
      }
      // eslint-disable-next-line
  }, [timer])

  if(!(window.MediaRecorder || window.webkitMediaRecorder)) {
    return(
      <div className='error'>
        Unfortunately, this browser does not support the web technology
        that powers this app. We recommend desktop Chrome or Firefox.
      </div>
    )
  }

  return (
    <>
      <canvas 
        style={{display: 'none'}}
        ref={canvasRef} 
        width={videoConstraints.width || canvasConstraints.width} 
        height={videoConstraints.height || canvasConstraints.height} />
      <div className={`capture-overlay ${showTimer ? 'show': 'hide'}`}>
        {timer === 1 ? 3 : timer === 2 ? 2 : timer === 3 ? 1 : ''}
      </div>
      { error ? <div className='camera-error'>{(error)}</div>:
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={videoConstraints.width || canvasConstraints.width}
          height={videoConstraints.height || canvasConstraints.height}
          videoConstraints={videoConstraints.width ? videoConstraints : canvasConstraints}
          style={{ objectFit: "cover"}}
          onUserMediaError={showAccessBlocked}
        />
      }
      {!error ? <div onClick={triggerCountDown}>
        {props.children}
      </div> : null}
    </>
  );
};
