export default {
  api: {
    local: {
      AUTH_CMS: 'https://.api.fankave.com/cmsa/',
      CONTENT_URL: 'https://api.fankave.com/v1.0/cms/content/',
      CMS_X: 'https://api.fankave.com/cmsx/',
      OKTA_URL: 'https://int-id.cisco.com',
      OKTA_CLIENT_ID: '0oax0xjlqRrWQsEoA1d6',
      OKTA_REDIRECT_URI: '/implicit/callback'
    },
    dev: {
      AUTH_CMS: 'https://api.fankave.com/cmsa/',
      CONTENT_URL: 'https://api.fankave.com/v1.0/cms/content/',
      CMS_X: 'https://api.fankave.com/cmsx/',
      OKTA_URL: 'https://int-id.cisco.com',
      OKTA_CLIENT_ID: '0oax0xjlqRrWQsEoA1d6',
      OKTA_REDIRECT_URI: '/implicit/callback'
    },
    prod: {
      AUTH_CMS: 'https://api.fankave.com/cmsa/',
      CONTENT_URL: 'https://api.fankave.com/v1.0/cms/content/',
      CMS_X: 'https://api.fankave.com/cmsx/',
      OKTA_URL: 'https://id.cisco.com',
      OKTA_CLIENT_ID: '0oa1f7qha1UW1t5Zk5d7',
      OKTA_REDIRECT_URI: '/myimpact'
    }
  }
};
