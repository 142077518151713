import React, { useState, useEffect, useRef } from 'react';

import {EXPERIENCE} from './../../experience/Stories';
import {videoConstraints, canvasConstraints} from './ImageRecorder'
import {StickerSelect} from './AddBgAndStickers';
import {TextArea} from './TextArea';
import {Input} from './Input';
import defaultUser from './../../assets/impact21/images/chat-bubble.PNG'
const LIMIT = 280;
const topic = 'impact21';
let width;
let height;

let uploadUrl = '';
// let videoThumb = '';
let hashtags = ['MyIMPACT'];
let hashtagSuggestions = ['FutureReady','CiscoIMPACT', 'InclusiveFuture', 'EmpoweredbyCisco'];

const imageExtensions = ['png', 'jpg', 'jpeg'];
let story = 'My Cisco IMPACT story';
// let user = {};
// let userInfo = {};
// let oktaToken = '';


const isAllowedFile = (name) => {
  try {
    const lastDot = name.lastIndexOf('.');
    const ext = name.substring(lastDot + 1);
    return imageExtensions.includes(ext.toLowerCase())
  } catch (err) {
    return false
  }
}

const resetData = () => {
  hashtags = ['MyIMPACT'];
  story = 'My Cisco IMPACT story';
  width='';
  height='';
}

const setWidthandHeight = (w, h) => {
  // const dimensions = {width, height};
  width = w;
  height = h;
}

export function UploadImage(props) {
  const [selectedFile, setSelectedFile] = useState()
  const [error, setError] = useState(false);
  const [errText, setErrText] = useState('');
  const canvasRef = useRef(null);

  const getSize = (image, container) => {
    // console.log('getSize');
    // console.log('image.width', image.width, image.height)
    const childRatio = image.width / image.height
    const parentRatio = container.width / container.height
    let width = container.width
    let height = container.height
    if (childRatio < parentRatio) {
      height = width / childRatio
    } else {
      width = height * childRatio
    }
    // console.log('width', 'height', width, height)
    return {
      width,
      height,
    }
  }

  const gotoPreview = () => {
    props.setExperience(EXPERIENCE.PREVIEW_UPLOAD)
  }
  const dropHandler = ev => {
    ev.preventDefault();
    // console.log('event dropHandler', ev);
    if (ev.dataTransfer.items) {
      if (ev.dataTransfer.items[0].kind === 'file') {
        var file = ev.dataTransfer.items[0].getAsFile();
        // console.log('if... file.name = ' + file, file.name, file.size);
        if(!isAllowedFile(file.name)) {
          // console.log('extions error', file.name);
          setErrText('File extension not allowed', file.name);
          setError(true)
          return;
        } else if( (file.size/1024/1024) > 5) {
          // console.log('size error', file.size, file.size/1024/1024);
          setErrText('File too large max 5Mb', file);
          setError(true)
          return;
        }
        let url = URL.createObjectURL(file);
        // console.log(url);
        // uploadUrl = url;
        // gotoPreview();
        const image = new Image();
        image.crossOrigin = 'Anonymous'
        image.src = url;
        image.onload = function() {
          // console.log('in-onload', image)
          const ctx = canvasRef.current.getContext('2d');
          // console.log('ctx', ctx);
           const {width, height} = getSize(image, {width: videoConstraints.width || canvasConstraints.width, height: videoConstraints.height || canvasConstraints.height})
          // console.log('width', width, 'height', height);
          setWidthandHeight(videoConstraints.width || canvasConstraints.width, videoConstraints.height || canvasConstraints.height);
          ctx.drawImage(image, 0, 0, width, height);
          const src = canvasRef.current.toDataURL('image/jpeg', 1.0);
          // setTimeout(() => {
            // console.log('src', src);
            // cb(src, videoConstraints);
          uploadUrl = src;
          gotoPreview();
          // }, 3000)
        }
      }      
    } else {
      // console.log('else... file.name = ' + ev.dataTransfer.files[0]);
    }
  }

  const dragOverHandler = e => {
    e.preventDefault();
    // console.log('event dragOverHandler', e)
  }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
      if (!selectedFile) {
          return
      }
      // console.log('slected file', selectedFile, selectedFile.name, selectedFile.size);
      if(!isAllowedFile(selectedFile.name)) {
        // console.log('extions error', selectedFile.name);
        setErrText('File extension not allowed', selectedFile.name);
        setError(true)
        return;
      } else if( (selectedFile.size/1024/1024) > 5) {
        // console.log('size error', selectedFile.size, selectedFile.size/1024/1024);
        setErrText('File too large max 5Mb', selectedFile);
        setError(true)
        return;
      }
        // console.log('imageSrc', imageSrc);
        const objectUrl = URL.createObjectURL(selectedFile)
      // uploadUrl = objectUrl
        const image = new Image();
        image.crossOrigin = 'Anonymous'
        image.src = objectUrl;
        image.onload = function() {
          // console.log('in-onload', image)
          const ctx = canvasRef.current.getContext('2d');
          // console.log('ctx', ctx);
          const {width, height} = getSize(image, {width: videoConstraints.width || canvasConstraints.width, height: videoConstraints.height || canvasConstraints.height})
          // console.log('width', width, 'height', height);
          // console.log(canvasRef.current.getBoundingClientRect())
          setWidthandHeight(videoConstraints.width || canvasConstraints.width, videoConstraints.height || canvasConstraints.height);
          ctx.drawImage(image, 0, 0, width, height);
          // console.log(videoConstraints.width)
          const src = canvasRef.current.toDataURL('image/jpeg', 1.0);
        // setTimeout(() => {
          // console.log('src', src);
          // cb(src, videoConstraints);
          uploadUrl = src;
          gotoPreview();
        // }, 3000)
        }
      
      // free memory when ever this component is unmounted
      // return () => URL.revokeObjectURL(objectUrl)
  // }, [selectedFile]
  // )
  // eslint-disable-next-line
    },[selectedFile])

  const onSelectFile = e => {
      if (!e.target.files || e.target.files.length === 0) {
          setSelectedFile(undefined)
          return
      }

      // I've kept this example simple by using the first image instead of multiple
      setSelectedFile(e.target.files[0])
  }

  return (
    <div className='container upload-image'>
    <div className='top-left-logo'></div>
      <div className='upload-image-container'>
        <div className='sub-heading'>Upload Image</div>
        <div className='heading'>Image Upload</div>
        <canvas 
        style={{display: 'none'}}
        ref={canvasRef} 
        width={videoConstraints.width || canvasConstraints.width} 
        height={videoConstraints.height || canvasConstraints.height} />
        {/* <div className='image-live-preview'>
          {selectedFile &&  <img src={preview} alt='preview'/> }
        </div> */}
        <div className='drag-n-drop' onDrop={dropHandler} onDragOver={dragOverHandler}>
          <div className='dnd-title'>
            Drag and Drop File
          </div>
          <p>or</p>
          <div className='file-upload'>
            <label htmlFor="file-upload" className="custom-file-upload">
              <i className="icon-fk icon-fk-up-circle"></i>
              <span className='text'>Upload Image</span>
            </label>
            <input id="file-upload" type="file" accept="image/*" onChange={onSelectFile}/>
          </div>
        </div>
        <div className='info'>
          <div className='instructions'>Choose an image with a light background to optimize your photo</div>
          <div className='instructions'>Images can be jpg, jpeg, or png and must have been taken in portrait mode</div>
          <div className='instructions'>The image file size is limited to 5MB</div>
        </div>
        {error && <div className='error'>{errText} </div>}
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function UploadPreview(props) {
  // console.log('upload image url', uploadUrl);
  const [hide, setHide] = useState(false);
  function setExperience(val) {
    props.setExperience(val);
  }

  function getImage() {
    hideControls();
    setTimeout(() => {
      const canvas2 = document.getElementById('fabricCanvas');
      uploadUrl = canvas2.toDataURL();
      return props.setExperience(EXPERIENCE.ADD_UPLOAD_POST);
    }, 1000)
  }

  const hideControls = () => {
    // console.log('hide set to true');
    setHide(true);
  }
  return (
    <div className='container upload-preview'>
      <div className='top-left-logo'></div>
      <div className='upload-preview-container'>
        <div className='sub-heading'>Upload Image</div>
        <div className='heading'>Stickers and Backgrounds</div>
        <div className='upload-preview'>
          <img src={uploadUrl} alt='preview' />
          <StickerSelect photo={uploadUrl} hideControls={hide}/>
        </div>
        <div className='action'>
          <div className='redo' onClick={() => setExperience(EXPERIENCE.UPLOAD_IMAGE)}>  
            {/* <i className='icon-fk icon-fk-left-arrow'></i> */}
            <span className='text'>Replace Image</span>
          </div>
          <div className='continue' onClick={getImage}>
            Continue
          </div>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function AddUploadPost(props) {
  const [limit, setLimit] = useState(LIMIT);
  const [hashtag, setHashtags] = useState(hashtags);
  const [firstLanding, setFirstLanding] = useState(true);
  function getText(value) {
    // console.log('limit', value.length);
    if(value.length > LIMIT) {
      return
    }
    story = value;
    // console.log('value', value, value.length)
    setLimit(LIMIT - value.length);
  }
  function resetHashtags(arr) {
    hashtags=arr;
    setHashtags(arr)
  }
  function removeHashtag(tag) {
    // console.log('state', hashtag)
    let newHashtags = hashtag.filter(e => e!==tag);

    resetHashtags(newHashtags);
  }
  function getHashtagText(value) {
    addHashtags(value);
  }
  function goToReview() {
    // console.log('lmi', limit)
    setFirstLanding(false);
    if(limit < LIMIT) {
      props.setExperience(EXPERIENCE.UPLOAD_POST_REVIEW)
    }
  }
  function addHashtags(tag) {
    let newHashtag = [...hashtag];
    // console.log(newHashtag.length, typeof newHashtag);
    if(newHashtag.length >= 4) {
      return;
    }
    newHashtag.push(tag)
    hashtags = newHashtag;
    setHashtags(newHashtag)
  }

  useEffect(() => {
    if(story) {
      setLimit(LIMIT - story.length)
    }
  }, [])
  // console.log('story', story);
  // console.log('hashtags', hashtags)
  return (
    <div className='container full-upload'>
      <div className='top-left-logo'></div>
      <div className='full-upload-container'>
        <div className='sub-heading'>Upload Image</div>
        <div className='heading'>Create Your Shared Story</div>
        <div className='add-post-container'>
          <div className='upload-preview-and-retake'>
            <img src={uploadUrl} alt='preview' />
            <div className='action-retake'>
              <div onClick = {() => props.setExperience(EXPERIENCE.UPLOAD_IMAGE)}className='btn-redo'>Replace Image</div>
            </div>
          </div>
          <div className='add-story-container'>
            <div className='user-info'>
              <img src={defaultUser} alt="user"/>
              <div className='username'>
                <span className='firstname'>{props.userInfo.first_name}</span>
                <span className='lastname'>{props.userInfo.last_name}</span>
              </div>
            </div>
            <div className='story-container'>
              <div className='heading'>Shared Story</div>
              <div className='sub-heading'>Add caption with up to 280 characters</div>
              <div className={`text-area ${(limit === LIMIT) && !firstLanding ? 'error': ''}`}>
                <TextArea 
                  placeholder="My Cisco IMPACT story  [ Shared Story Caption ]"
                  value={story} 
                  getText={getText} 
                  limit={LIMIT}>
                </TextArea>
                <div className='text-area-meta'>
                  <span className='error-msg'>You must enter a story to continue</span>
                  <span className='character-count'>{limit}</span>
                </div>
              </div>
            </div>
            <div className='hashtag-container'>
              <div className='heading'>Hashtags</div>
              <div className='sub-heading'>Type a Hashtag or Select a Suggested Hashtag (Up to 4 total)</div>
              <div className={`hashtags ${hashtags.length >=4 ? 'hideinput': ''}`}>
                {hashtags.map((hash, i) => 
                  <div className='hashtag-wrapper' key={hash}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    {i === 0 ? null : <i className='icon-fk icon-fk-cancel-circle' onClick={() => removeHashtag(hash)}></i> }
                  </div>)}
                <Input placeholder="Enter a #hashtag" getText={getHashtagText}></Input>
              </div>
              <div className='hashtags-suggestion'>
                {hashtagSuggestions.filter(e => !hashtags.includes(e)).map((hash) => (
                  <div key={hash} className='hashtag-wrapper' onClick={() => addHashtags(hash)}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    <i className='icon-fk icon-fk-plus'></i>
                    <div className='image icon-fk-add'></div>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
        </div>
        <div className='action-container'>
          <div className='back' onClick={() => props.setExperience(EXPERIENCE.PREVIEW_UPLOAD)}>
          <i className='icon-fk icon-fk-left-arrow'></i>
            <div className='image back-icon-fk'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={goToReview}>
            <span className='text'>Preview Story</span>
          </div>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function UploadPostReview(props) {
  const [publish, setPublishAction] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  function setExperience(val) {
    props.setExperience(val);
  }

  function publishPost() {
    setPublishAction(true)
    setApiLoading(true);
  }

  useEffect(() => {
    if(publish) {
      // console.log('make api call');
      // console.log('take use to download page');
      // const blob = dataURLtoBlob(image);
      fetch(uploadUrl).then(res => res.blob()).then(blob => {
        const formData = new FormData();
        formData.append("type", "upload");
        formData.append("media", blob);
        formData.append("story", story);
        formData.append("hashtags", JSON.stringify(hashtags));
        formData.append("author", JSON.stringify({name: props.userInfo.full_name, email: props.userInfo.email_address}));
        formData.append("width", width);
        formData.append("height", height);
        // console.log(props.userInfo);
        fetch(`https://api.fankave.com/cmsa/stories/${topic}/publish?x-access-token=${props.token}`, {
          method: 'POST',
          body: formData
        }).then(e => {
          console.log('published successfully', e);
          setExperience(EXPERIENCE.DOWNLOAD_UPLOAD)
        }).catch(err => {
          console.error("Error: ", err);
          setExperience(EXPERIENCE.DOWNLOAD_UPLOAD)
        })
      });
    }
  })
  return (
    <div>
      <div className='container upload-review'>
        <div className='heading-out'>Preview Story</div>
        <div className='top-left-logo'></div>
      <div className={`spinner ${apiLoading ? '' : 'hide'}`}></div>
      <div className='upload-post-review-container'>
        <div className='upload-preview'>
          <img src={uploadUrl} alt='preview' />
        </div>
        <div className='story'>
          {story}
        </div>
        <div className='hashtags'>
        {hashtags.map((hash, i) => 
                  <div className='hashtag-wrapper' key={hash}>
                    <div className='hashtag'>{`#${hash}`}</div>
                  </div>)}
        </div>
        <div className='user-info'>
          <img src={defaultUser} alt="preview"/>
            <div className='username'>
              <span className='firstname'>{props.userInfo.first_name}</span>
              <span className='lastname'>{props.userInfo.last_name}</span>
            </div>
          </div>
      </div>
      <div className='action'>
        <div className='back' onClick={() => setExperience(EXPERIENCE.ADD_UPLOAD_POST)}>
          <i className='icon-fk icon-fk-left-arrow'></i>
          <div className='image back-icon-fk'></div>
          <span className='text'>Back</span>
        </div>
        <div className='continue' onClick={publishPost}>
          <span className='text'>Publish Story</span>
        </div>
      </div>
      <div className='btn' onClick={() => props.setExperience()}>Cancel</div>
      <div className='footer'></div>
      </div>
    </div>
  )
}

export function UploadDownload(props) {
  function setExperience(val) {
    resetData();
    props.setExperience(val);
  }
  return (
    <div className='container upload-download'>
      <div className='top-left-logo'></div>
      <div className='title'>
      Shared Story Posted
      </div>
      <div className='tagline'>
      Great news, your story is out there for all to see! Head over to the Shared Stories gallery to see how it looks and explore other stories.  Or, if you have more to say, create another one.
      </div>
      <div className='upload-download-container'>
        <div className='upload-download-preview'>
          <img src={uploadUrl} alt='preview' />
          <div className='filename'>Photo.png</div>
          <a href={uploadUrl} download="photo.png">
          <div className='download'>
            <i className='icon-fk icon-fk-download'/>
            <div className='dwnld-img' download="photo.png" href={uploadUrl}>
              <div className='image icdownload'></div>
              Download
            </div>
          </div>
          </a>
        </div>
        <div className='action'>
          <div className='close' onClick={() => {window.close()}}>{`Close & View Stories`}</div>
          <div className='redo' onClick={() => setExperience('')}>Create a New Story</div>
        </div>
      </div>
      <div className='footer'></div>
    </div>
  )
}
