import React, { useState, useEffect} from 'react';
import {ImageCapture, ImagePostReview, ImagePreview, AddImagePost, ImageDownload} from './../component/Stories/ImageViews';
import {RecordVideo, RecordVideoPreview, AddVideoPost, VideoPostReview, VideoDownload} from '../component/Stories/VideoViews';
import { UploadImage, UploadPreview, AddUploadPost, UploadPostReview, UploadDownload } from '../component/Stories/UploadViews';

export const EXPERIENCE = {
  'CANCEL': 'CANCEL',
  'CAPTURE_IMAGE': 'CAPTURE_IMAGE',
  'PREVIEW_IMAGE': 'PREVIEW_IMAGE',
  'ADD_IMAGE_POST': 'ADD_IMAGE_POST',
  'IMAGE_POST_REVIEW': 'IMAGE_POST_REVIEW',
  'DOWNLOAD_IMAGE': 'DOWNLOAD_IMAGE',

  'RECORD_VIDEO': 'RECORD_VIDEO',
  'PREVIEW_VIDEO': 'PREVIEW_VIDEO',
  'ADD_VIDEO_POST': 'ADD_VIDEO_POST',
  'VIDEO_POST_REVIEW': 'VIDEO_POST_REVIEW',
  'DOWNLOAD_VIDEO': 'DOWNLOAD_VIDEO',

  'UPLOAD_IMAGE': 'UPLOAD_IMAGE',
  'PREVIEW_UPLOAD': 'PREVIEW_UPLOAD',
  'ADD_UPLOAD_POST': 'ADD_UPLOAD_POST',
  'UPLOAD_POST_REVIEW': 'UPLOAD_POST_REVIEW',
  'DOWNLOAD_UPLOAD': 'DOWNLOAD_UPLOAD',
};

export const resetData = () => {

}

let oktaToken = '';
let userInfo = '';

export function Story(props) {
  function captureImage() {
    // console.log('capture image')
    props.setExperience(EXPERIENCE.CAPTURE_IMAGE);
  }
  function recordVideo(){
    props.setExperience(EXPERIENCE.RECORD_VIDEO);
  }
  function uploadImage() {
    props.setExperience(EXPERIENCE.UPLOAD_IMAGE);
  }
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const accessToken = url.searchParams.get('accessToken');
    oktaToken = accessToken;
    setLoading(true);
    fetch(`https://api.fankave.com/cmsa/verifyToken?x-access-token=${accessToken}`)
    .then(e => e.json())
    .then(e => {
      // console.log('token valid, load user info into memory')
      // setUser({full_name: 'alen thomas', first_name: 'alen', last_name: 'thomas'});
      // setLoading(false);
      // return;
      // console.error('Error validating user', err);


      if(e && e.active) {
        userInfo = e;
        // console.log('USER', e);
        setUser(e);
        setLoading(false);
      } else {
        setUser(false);
        setLoading(false);
      }
      setLoading(false);
    }).catch(err => {
      // setUser(false);
      // setLoading(false);
      // console.error('Error validating user', err);
      setUser(false);
      setLoading(false);
      console.error('Error validating user', err);
    })
  }, [])
  if(loading) {
    return (
      <div className='action-container'>
        <div className='spinner'></div>
      </div>
    )
  }
  if(!user) {
    return (
      <div className='action-container'>
        <div className='auth-error'>
          Unauthorized User
        </div>
      </div>
    )
  }
  return (
    <div className='action-container'>
      <div className='top-left-logo'></div>
      <div className='title'>
        Shared Stories
      </div>
      <div className='tagline'>
      This past year has been a journey.  Share your personal or professional story so we can learn from each other and be Future Ready together!
      </div>
      <div className='action-items'>
        <div className='action-card first'>
          <div className='action-pic'>
            <i className='icon-fk icon-fk-camera'></i>
            <div className='image camera'></div>
          </div>
          <div className='action-heading'>
            Capture a Photo
          </div>
          <div className='action-description camera'>
          Start by adding a photo here.  Add fun stickers to let your personality shine.
          </div>
          <div className='action-btn-bg'>
            <div className='action-btn' onClick={captureImage}>
              <div>Start</div>
            </div>
          </div>
        </div>
        <div className='action-card second'>
          <div className='action-pic'>
            <i className='icon-fk icon-fk-video'></i>
            <div className='image video'></div>
          </div>
          <div className='action-heading'>
            Record a Video
          </div>
          <div className='action-description video'>
          Add some energy to your story with a video. Click below
          </div>
          <div className='action-btn-bg'>
            <div className='action-btn' onClick={recordVideo}>
              <div>Start</div>
            </div>
          </div>
        </div>
        <div className='action-card third'>
          <div className='action-pic'>
            <i className='icon-fk icon-fk-up-circle'></i>
            <div className='image upload'></div>
          </div>
          <div className='action-heading'>
            Upload Image
          </div>
          <div className='action-description upload'>
          Already have the perfect image for your story? Upload it here
          </div>
          <div className='action-btn-bg'>
            <div className='action-btn' onClick={uploadImage}>
            <div>Start</div>
            </div>
          </div>
        </div>
        <div className='action-card fourth'>
          <div className='action-pic'>
            <i className='icon-fk icon-fk-twitter'></i>
            <div className='image twitter'></div>
          </div>
          <div className='action-heading'>
            Social Upload
          </div>
          <div className='action-description twitter'>
          Don't forget to share your video or image on social media. Be sure to include <strong>#ciscoimpact</strong> so everyone can enjoy it.
          </div>
          <div className='action-btn-bg'>
            <div className='action-btn' onClick={uploadImage}>
            <div>Start</div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer'>
      </div>
    </div>
  )
}

export default function StoryFlow() {
  let [experience, setExperience] = useState(null);
  switch(experience) {
    case EXPERIENCE.CAPTURE_IMAGE:
      return <ImageCapture setExperience={setExperience} />;
    case EXPERIENCE.PREVIEW_IMAGE:
      return <ImagePreview setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.ADD_IMAGE_POST:
      return <AddImagePost setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.IMAGE_POST_REVIEW:
      return <ImagePostReview setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.DOWNLOAD_IMAGE:
      return <ImageDownload setExperience={setExperience} userInfo={userInfo} token={oktaToken} />
    case EXPERIENCE.RECORD_VIDEO:
      return <RecordVideo setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.PREVIEW_VIDEO:
      return <RecordVideoPreview setExperience={setExperience} userInfo={userInfo} token={oktaToken}  />;
    case EXPERIENCE.ADD_VIDEO_POST:
      return <AddVideoPost setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.VIDEO_POST_REVIEW:
        return <VideoPostReview setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.DOWNLOAD_VIDEO:
      return <VideoDownload setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.UPLOAD_IMAGE:
      return <UploadImage setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.PREVIEW_UPLOAD:
      return <UploadPreview setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.ADD_UPLOAD_POST:
      return <AddUploadPost setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.UPLOAD_POST_REVIEW:
      return <UploadPostReview setExperience={setExperience} userInfo={userInfo} token={oktaToken} />;
    case EXPERIENCE.DOWNLOAD_UPLOAD:
      return <UploadDownload setExperience={setExperience} userInfo={userInfo} token={oktaToken} />
    case EXPERIENCE.CANCEL:
      return <Story setExperience={setExperience} userInfo={userInfo} token={oktaToken} />
    default:
      return <Story setExperience={setExperience} userInfo={userInfo} token={oktaToken} />
  }
}
