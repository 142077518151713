import React, { useState, useEffect, useReducer, useRef } from "react";
import _ from "lodash";

import { Header } from "./Header/Header";
import { Story } from "./Story/Story";
import { Overlay } from "./Overlay/Overlay";
import { Loader } from "./Loader/Loader";
import { NoContent } from "./BlankScreens/NoContent";

import {
  getImpactGallery,
  getImpactUserMetrics,
  updateUserMetrics,
} from "../../Services";
import { getEmbedThemeInfo } from "../../theme/Loader";
import { useAnalyticsContext } from "../../Providers/AnalyticsProvider";
import { makeFilters, transformData } from "./utils";

import "./impactGalleryMobile.css";

const reducer = (state, newState) => {
  return { ...state, ...newState };
};

export default ({ width, height, refreshToken = () => {} }) => {
  const galleryRef = useRef(null);
  const headerRef = useRef(null);
  const themeConfig = getEmbedThemeInfo();
  const { dispatchAnalyticsEvent } = useAnalyticsContext();
  const initialState = {
    apiLimit: _.get(themeConfig, "gallery.apiLimit") || 60,
    stories: [],
    startStory: null,
    endStory: null,
    visited: localStorage.getItem("visited"),
    filters: {
      sort: ["Latest"],
    },
    rc: false,
  };

  const [
    { apiLimit, stories, startStory, endStory, visited, filters, rc },
    dispatch,
  ] = useReducer(reducer, initialState);
  const [isLoading, setLoading] = useState(false);
  const [isPaginating, setIsPagination] = useState(false);

  useEffect(() => {
    if (galleryRef.current) {
      galleryRef.current.onscroll = scroll;
    }
    // eslint-disable-next-line
  }, [galleryRef.current]);

  const scroll = (e) => {
    const element = e.target;
    if (element.scrollTop + element.offsetHeight + 1 >= element.scrollHeight) {
      if (!isPaginating) {
        dispatchAnalyticsEvent("PAGINATE_GALLERY", {
          type: "older",
          source: "MOBILE",
        });
      }
      return !isPaginating && paginateStories("older");
    }
    if (element.scrollTop === 0) {
      if (!isPaginating) {
        dispatchAnalyticsEvent("PAGINATE_GALLERY", {
          type: "newer",
          source: "MOBILE",
        });
      }
      return !isPaginating && paginateStories("newer");
    }
  };

  const getUserMetrics = (posts = []) => {
    const chunkSize = 20;
    let dataPromises = [];
    for (let i = 0; i < posts.length; i += chunkSize) {
      const chunk = posts.slice(i, i + chunkSize);
      const chunkPromise = new Promise((resolve, reject) => {
        getImpactUserMetrics(
          (results) => {
            chunk.map((post) => {
              post.setUserMetrics({
                isLiked: false,
              });
              return post;
            });
            return resolve();
          },
          (error) => {
            chunk.map((post) => {
              post.setUserMetrics({
                isLiked: false,
              });
              return post;
            });
            return resolve();
          },
          makeFilters({
            pid: chunk.map((post) => post.getId() || ""),
          })
        );
      });
      dataPromises.push(chunkPromise);
    }
    return new Promise((resolve, reject) => {
      Promise.all(dataPromises)
        .then(() => {
          resolve(transformData(posts));
        })
        .catch((error) => reject(error));
    });
  };

  const getStories = (params) => {
    setLoading(true);
    return getImpactGallery(
      async (posts) => {
        const data = await getUserMetrics(posts);
        dispatch({
          rc: posts.length > apiLimit - 1,
          stories: data,
          startStory: _.get(data, "[0].id"),
          endStory: _.get(data, `[${data.length - 1}].id`),
        });
        setLoading(false);
      },
      (error) => {
        if (error.name === "UnauthorizedError") {
          refreshToken();
        }
        setLoading(false);
      },
      params
    );
  };

  const paginateStories = (type) => {
    setIsPagination(true);
    const isNewer = type === "newer";
    if (isNewer && headerRef.current) {
      headerRef.current.getSearchOptions();
    }
    return new Promise((resolve) => {
      const queryParams = {
        temporality: type,
        id: isNewer ? startStory : endStory,
        limit: 60,
        ...makeFilters(filters),
        ...(!isNewer && rc ? { rc } : {}),
      };
      return getImpactGallery(
        async (posts) => {
          const data = await getUserMetrics(posts);
          const newStories = isNewer
            ? [...data.reverse(), ...stories]
            : [...stories, ...data];
          dispatch({
            stories: newStories,
            startStory: _.get(newStories, "[0].id"),
            endStory: _.get(newStories, `[${newStories.length - 1}].id`),
          });
          setIsPagination(false);
          resolve(data);
        },
        (error) => {
          if (error.name === "UnauthorizedError") {
            refreshToken();
          }
          setIsPagination(false);
          resolve([]);
        },
        queryParams
      );
    });
  };

  useEffect(() => {
    getStories({});
    // eslint-disable-next-line
  }, []);

  const handleAppliedFilter = (selectedFilters) => {
    const { sort } = filters;
    dispatch({
      stories: [],
      filters: { sort, ...selectedFilters },
    });
    getStories(makeFilters({ sort, ...selectedFilters }));
  };

  const handleOverlayAction = () => {
    localStorage.setItem("visited", true);
    dispatch({ visited: true });
  };

  const handleLike = (postIndex) => {
    const story = stories[postIndex] || {};
    if (story) {
      const { isLiked = false } = story.getUserMetrics() || {};
      const metrics = story.getMetrics() || {};
      const likes = metrics.likes + (isLiked ? -1 : 1);
      const updateMetics = () => {
        story.setUserMetrics({
          ...story.getUserMetrics(),
          isLiked: !isLiked,
        });
        story.setMetrics({
          ...metrics,
          likes,
        });
        stories.splice(postIndex, 1, story);
        dispatch({
          stories,
        });
      };
      updateUserMetrics(
        () => {
          updateMetics();
        },
        (error) => {
          if (error.name === "UnauthorizedError") {
            refreshToken();
          }
          updateMetics();
        },
        { pid: story.getId(), type: isLiked ? "unlike" : "like" }
      );
    }
  };

  return (
    <div className="fk-gallery-view-container-mobile" style={{ width, height }}>
      {isLoading && <Loader />}
      <Header
        ref={headerRef}
        selectedFilters={filters}
        refreshToken={refreshToken}
        onFilterApplied={handleAppliedFilter}
        isMobile
      />
      {stories.length ? (
        <div className="fk-story-wrapper" style={{ height: height - 200 }}>
          <div className="fk-scroll-loader">{isPaginating && <Loader />}</div>
          <div className="fk-story-wrapper-inner" ref={galleryRef}>
            {stories.map((story, index) => (
              <div className="fk-story" key={story.getId()}>
                <Story
                  story={story}
                  width={width > 400 ? 400 : width}
                  height={height - 40}
                  onLikeClick={() => handleLike(index)}
                />
              </div>
            ))}
          </div>
          <div className="fk-scroll-loader">{isPaginating && <Loader />}</div>
        </div>
      ) : isLoading ? (
        <></>
      ) : (
        <NoContent />
      )}
      {!visited && <Overlay isMobile onAction={handleOverlayAction} />}
    </div>
  );
};
