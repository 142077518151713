import bg1 from './../../assets/impact21/images/bgs/01.jpg'
import bg2 from './../../assets/impact21/images/bgs/02.jpg'
import bg3 from './../../assets/impact21/images/bgs/03.jpg'
import bg4 from './../../assets/impact21/images/bgs/04.jpg'
import bg5 from './../../assets/impact21/images/bgs/05.jpg'
import bg6 from './../../assets/impact21/images/bgs/06.jpg'

import st1 from './../../assets/impact21/images/stickers/01.png'
import st2 from './../../assets/impact21/images/stickers/02.png'
import st3 from './../../assets/impact21/images/stickers/03.png'
import st4 from './../../assets/impact21/images/stickers/04.png'
import st5 from './../../assets/impact21/images/stickers/05.png'
import st6 from './../../assets/impact21/images/stickers/06.png'
import st7 from './../../assets/impact21/images/stickers/07.png'
import st8 from './../../assets/impact21/images/stickers/08.png'
import st9 from './../../assets/impact21/images/stickers/09.png'
import React, {useRef, useEffect, useState} from 'react';
import {fabric} from 'fabric';
import axios from 'axios';

// import { isMobile } from "react-device-detect";
import {videoConstraints, canvasConstraints} from './ImageRecorder';

import corner from     './../../assets/impact21/images/corner.png';
import deleteIcon from './../../assets/impact21/images/delete.png';
import rotate from     './../../assets/impact21/images/rotate.png';
import scale from      './../../assets/impact21/images/scale.png';

let stickers = [st1, st2, st3, st4, st5, st6, st7, st8, st9];
let backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6];

const toDataURLPromise = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export const toDataURL = (url, callback) => {
  const xhRequest = new XMLHttpRequest();
  xhRequest.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhRequest.response);
  };
  xhRequest.open('GET', url);
  xhRequest.responseType = 'blob';
  xhRequest.send();
};

function loadImage(data) {
  const new_blob = new Blob([data], { type: 'image/png' });
  const url = URL.createObjectURL(new_blob);
  const img = document.createElement('img');
  img.crossOrigin = 'Anonymous'
  img.src = url;
  return new Promise((res, rej) => {
    img.onload = () => res(img);
    img.onerror = rej;
  });
}

// function loadImageUrl() {
//   const img = document.createElement('img');
//   return new Promise((res, rej) => {
//     img.onload = () => res(img);
//     img.onerror = rej;
//   });
// }

export const getError = (error) => {
  const decodedString = String.fromCharCode.apply(
    null,
    new Uint8Array(error.response.data)
  );
  const obj = JSON.parse(decodedString);
  return obj.errors.map((e) => e.title).join();
};

export const getBackgroundThirdParty = async (fg) => {

  const imageBlob = await fetch(fg.src).then(res => res.blob());
  // console.log('imageBlob', imageBlob);
  const formData = new FormData();
  formData.append("image_file", imageBlob);
  formData.append("size", "auto");
  return axios({
    data: formData,
    url: 'https://api.remove.bg/v1.0/removebg',
    method: 'POST',
    headers: {
      'X-Api-Key': 'Z6gXjpYCRj5GPfqCeDpyqXn7',
    },
    responseType: 'arraybuffer',
  })
    .then((resp) => {
      // console.log('resp', resp);
      return loadImage(resp.data)
    })
    .then((img) => {
      // console.log('img', img);
      return ({ url: img.src })
    })
    .catch((err) => ({ error: getError(err) }));
};

export const StickerSelect = (props) => {
  const fabricCanvas = useRef(null);
  const photoCanvas = useRef(null);
  const backgroundCache = useRef(null);
  // const [originalImage, setOriginalImage] = useState(null);
  const [currentBackground, setCurrentBackground] = useState(-1);
  // eslint-disable-next-line
  const [objectCount, setObjectCount] = useState(0);
  // const [apiCall, setApiCall] = useState({status: false, data: null});
  const [loading, setLoading] = useState(false);
  // const videoWidth =
  //   window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    // console.log('videoWidth', videoWidth, 'isMobile', isMobile);
// const videoConstraints = {
//   width: isMobile
//     ? undefined
//     : videoWidth > 375
//     ? 320
//     : videoWidth < 375
//     ? 286
//     : 286,
//   height: isMobile 
//     ? undefined 
//     : videoWidth > 375 
//     ? 480 
//     : videoWidth < 375 
//     ? 380 
//     : 380,
//   facingMode: "user",
// }

// const canvasConstraints = {
//   width: videoWidth > 375
//     ? 320
//     : videoWidth < 375
//     ? 286
//     : 286,
//   height: videoWidth > 375 
//     ? 480 
//     : videoWidth < 375 
//     ? 380 
//     : 380,
//   facingMode: "user",
// }
  
  const setBackground = (photoImage, width, height, options) => {
    // console.log('setBackground', photoImage._element.currentSrc)
    // console.log('setBackground');
    const bgImage = photoImage.set({
      left: 0,
      top: 0,
      ...options,
    });
    fabricCanvas.current.setBackgroundImage(bgImage);
    fabricCanvas.current.renderAll();
  };

  const loadUserImage = (canvas) => {
    // console.log('loadUserImage');
    const image = new Image();
    image.crossOrigin = 'Anonymous'
    // image.src = canvas.toDataURL('image/jpeg', 1.0);
    image.src = props.photo;
    return image; // returns a new Image Object
  };

  // const loadCanvasImage = (canvas) => {
  //   // console.log('loadUserImage');
  //   const image = new Image();
  //   image.src = canvas.toDataURL('image/jpeg', 1.0);
  //   // image.src = props.photo;
  //   return image; // returns a new Image Object
  // };

  const getImage = (image) => {
    // console.log('getImage')
    const rotateImg = document.createElement('img');
    rotateImg.crossOrigin = 'Anonymous'
    rotateImg.src = image;
    return rotateImg; // returns a html image element
  };

  const setOverLay = (imgURL, render) => {
    // console.log('setOverLay')
    fabricCanvas.current.setOverlayImage(imgURL, () => {
      // render first time capture photo after background change
      // before it was just used for capture photo after set background and overlay
      if (render) {
        fabricCanvas.current.renderAll();
      }
    });
  };

  const setBackgroundFromURL = (dataUrl, stickers) => {
    // console.log('setBackgroundFromURL', dataUrl);
    // console.log('setBackgroundFromURL')
    const jeeFaceFilterCanvas = document.getElementById('imageCanvas');
    const canvasWidth = jeeFaceFilterCanvas.width;
    const canvasHeight = jeeFaceFilterCanvas.height;
    fabric.Image.fromURL(
      dataUrl,
      (photoImage) => {
        const scaleX = canvasWidth / photoImage.width;
        const scaleY = canvasHeight / photoImage.height;
        setBackground(
          photoImage,
          photoCanvas.current.width,
          photoCanvas.current.height,
          { scaleX, scaleY }
        );
        if(stickers) {
          // console.log('adding stickers', stickers)
          stickers.map(sticker => fabricCanvas.current.add(sticker))
          fabricCanvas.current.renderAll();
        }
      },
      { crossOrigin: 'Anonymous' }
    );
  };

  const getObjects = (count) => {
    // console.log('getObjects');
    const objects = fabricCanvas.current?.getObjects() || [];
    // console.log('objects', objects);
    if (count) return objects.length;
    return objects;
  };

  const getSetObjects = () => {
    // console.log('getSetObjects');
    const stickers = getObjects();
    removeAllSticker();
    const photo = fabricCanvas.current.toDataURL('image/jpeg', 1.0);
    // setOverLay(0);
    fabricCanvas.current.overlayImage = null;
    fabricCanvas.current.renderAll();

    // added timeout for set background for might be remove overlay take time;
    // console.log('setTimeout 3000')
    setTimeout(() => {
      setBackgroundFromURL(photo, stickers);
    }, 100);
  };

  const deleteObject = () => {
    // console.log('deleteObject');
    const activeObject = fabricCanvas.current.getActiveObject();
    if (activeObject) {
      fabricCanvas.current.remove(activeObject);
    }
    setObjectCount(getObjects(true));
  };

  // const handleDeleteObject = (e) => {
  //   // console.log('handleDeleteObject');
  //   if ([8, 46].includes(e.keyCode)) {
  //     deleteObject();
  //   }
  // };

  function renderIcon(icon) {
    // console.log('renderIcon');
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      const size = 40;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  }

  const initFabricCanvas = (imgURL, width, height, fg) => {
    // console.log('initFabricCanvas');
    // console.log('params', imgURL, width, height, fg);
    const fabricElement = document.getElementById('fabricCanvas');
    // console.log('fabricElement', fabricElement);
    const cssWidth = fabricElement.style.width;
    const cssHeight = fabricElement.style.height;
    fabricCanvas.current = new fabric.Canvas('fabricCanvas', {
      enableRetinaScaling: false,
    });
    fabricCanvas.current.setWidth(width);
    fabricCanvas.current.setHeight(height);
    fabricCanvas.current.setDimensions(
      { width: cssWidth, height: cssHeight },
      { cssOnly: true }
    );
    const controlsUtils = fabric.controlsUtils;
    const scaleStyleHandler = controlsUtils.scaleCursorStyleHandler,
      scalingEqually = controlsUtils.scalingEqually,
      objectControls = fabric.Object.prototype.controls;

    objectControls.tl = new fabric.Control({
      x: -0.56,
      y: -0.56,
      actionHandler: deleteObject,
      cursorStyle: 'pointer',
      render: renderIcon(getImage(deleteIcon)),
      cornerSize: 30,
      sizeX: 40,
      sizeY: 40,
      mouseUpHandler: deleteObject,
    });

    objectControls.tr = new fabric.Control({
      x: 0.57,
      y: -0.57,
      withConnection: false,
      actionName: 'rotate',
      cursorStyle: 'pointer',
      render: renderIcon(getImage(rotate)),
      cornerSize: 24,
      actionHandler: controlsUtils.rotationWithSnapping,
    });

    objectControls.br = new fabric.Control({
      x: 0.57,
      y: 0.57,
      cursorStyleHandler: scaleStyleHandler,
      actionHandler: scalingEqually,
      render: renderIcon(getImage(scale)),
      cornerSize: 24,
    });

    objectControls.bl = new fabric.Control({
      x: -0.57,
      y: 0.57,
      cursorStyleHandler: scaleStyleHandler,
      actionHandler: scalingEqually,
      render: renderIcon(getImage(corner)),
      cornerSize: 24,
    });
    setBackgroundFromURL(imgURL);
  };

  const loadOnFabricCanvas = (image) => {
    // console.log('loadOnFabricCanvas', image, image.width, image.height);
    image.addEventListener(
      'load',
      () => {
        // console.log('image onload width and height', image.width, image.height, image)
        photoCanvas.current = document.createElement('canvas');
        photoCanvas.current.width = videoConstraints.width || canvasConstraints.width;
        photoCanvas.current.height = videoConstraints.height || canvasConstraints.height;

        const ctx = photoCanvas.current.getContext('2d');
        // ctx.translate(image.width, 0);
        ctx.drawImage(image, 0, 0, photoCanvas.current.width, photoCanvas.current.height) //, 0, 0, 320, 480);
        ctx.scale(2, 2);
        // ctx.drawImage(image, 0, 0, 320, 480, 0, 0, 100, 100);
        // console.log('dataurl', photoCanvas.current.toDataURL());
        initFabricCanvas(
          photoCanvas.current.toDataURL(),
          photoCanvas.current.width,
          photoCanvas.current.height
          // 320,
          // 480
        );
      },
      false
    );
  };

  const showPhoto = () => {
    // console.log('showPhoto');
    const canvas = document.getElementById('imageCanvas');
    canvas.style.display = 'none';
    const image = loadUserImage(canvas);
    loadOnFabricCanvas(image);
    const fabCanvas = document.getElementById('fabricCanvas');
    fabCanvas.style.display = 'block';
  };

  const removeAllSticker = () => {
    // console.log('removeAllSticker');
    fabricCanvas.current.remove(...fabricCanvas.current.getObjects());
    setObjectCount(getObjects(true));
  };

  const addSticker = (selectedSticker, width, height, options = {}) => {
    // console.log('addSticker');
    fabric.Image.fromURL(selectedSticker, (sticker) => {
      // console.log('in addSticker');
      // console.log('selected Sticker', selectedSticker);
      // console.log('sticker', sticker);
      sticker
        .set({
          left: Math.floor(Math.random() * 200),
          top: Math.floor(Math.random() * 100),
          angle: 0,
          cornerColor: '#E6F7E2',
          strokeWidth: 12,
          cornerSize: 23,
          cornerStrokeColor: '#E6F7E2',
          cornerStyle: 'rect',
          cornerWidth: 10,
          borderScaleFactor: 8,
          borderColor: '#E6F7E2',
          touchCornerSize: 40,
          borderDashArray: [10],
          ...options,
        })
        .setControlsVisibility({
          bl: true,
          br: true,
          tl: true,
          tr: true,
          mb: false,
          ml: false,
          mr: false,
          mt: false,
          mtr: false,
        })
        .scale(1)
        .setCoords();
      sticker.scaleToHeight(200);
      sticker.scaleToWidth(200);
      fabricCanvas.current.add(sticker);
      setObjectCount(getObjects(true));
      if (getObjects(true) > 0) {
        setTimeout(() => {
          getSetObjects();
        }, 300);
      }
    });
  };

  const handleStickerAdd = async (index) => {
    // console.log('handleStickerAdd');
    if (index === -1) return removeAllSticker();
    const resolvedStickers = await Promise.all(stickers.map(e => toDataURLPromise(e)));
    // console.log('resolvedStocke', resolvedStickers);
    addSticker(resolvedStickers[index], 200, 200);
  };

  const handleChangeBackground = async (index) => {
    // console.log('handleChangeBackground');
    if (index === currentBackground) return;
    if (index === -1) {
    } else {
    }
    setLoading(true);
    setCurrentBackground(index);
    if (index < 0) {
      // console.log('current canvas toDataURL()', photoCanvas.current.toDataURL())
      setBackgroundFromURL(photoCanvas.current.toDataURL());
      setLoading(false);
      return;
    }

    try {
      // setPhotoInformation({ loading: true });
      // console.log('handleChangeBackground try')
      toDataURL(backgrounds[index], async (dataUrl) => {
        // console.log('handleChangeBackground toDataUrl callback');
        const fg = loadUserImage(fabricCanvas.current);
        // const fgFile = dataURLtoFile(fg.src, 'fg.png');
        let error;
        if (!backgroundCache.current) {
          const { url, error: bgError } = await getBackgroundThirdParty(fg);
          backgroundCache.current = url;
          error = bgError;
          // setApiCall({status: true, data: url});
        }
        if (!error) {
          setBackgroundFromURL(dataUrl);
          setOverLay(backgroundCache.current, true);
          if (getObjects(true) > 0) {
            setTimeout(() => {
              getSetObjects();
            }, 200);
          }
        }
        setLoading(false);
        // setPhotoInformation({ loading: false });
      });
    } catch (e) {
      console.log('e', e);
      // setPhotoInformation({ loading: false });
    }
  };

  useEffect(() => {
    // console.log('useEffect');
    // setOriginalImage(props.image);
    // setTimeout(() => {console.log(originalImage)}, 2000);
    showPhoto();
    // document.addEventListener('keydown', handleDeleteObject, false);
    // return () => {
      // console.log('in cleanup')
      // const fabCanvas = document.getElementById('fabricCanvas');
      // const ctx = fabCanvas.getContext('2d');
      // ctx.clearRect(0, 0, fabCanvas.width, fabCanvas.height);
      // fabricCanvas.current.dispose();
      // document.removeEventListener('keydown', handleDeleteObject, false);
    // };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Used for removing sticker controls before performing a canvasToDataUrl
    if(props.hideControls) {
      getSetObjects();
    }
      // eslint-disable-next-line
  }, [props.hideControls])

  return (
    <div className='sticker-container'>
      <div className={`spinner ${loading?'show': 'hide'}`}></div>
      <div className='canvas-container'>
        <canvas width={videoConstraints.width || canvasConstraints.width} height={videoConstraints.height || canvasConstraints.height} className="fabric" id="fabricCanvas" />
        <canvas width={videoConstraints.width ||canvasConstraints.width} height={videoConstraints.height || canvasConstraints.height} className="canvas" id="imageCanvas" />
      </div>
      <div className='select-sticker-container'>
        <div className='heading'>Add Stickers</div>
        <div className='sub-heading'>You can select multiple stickers</div>
        <div className='stickers-preview'>
        <div className='sticker clear' 
              onClick={() => removeAllSticker()}>
                <i className='icon-fk icon-fk-cancel-circle'></i>
                Clear All
            </div>
          {stickers.map((sticker, index) => (
            <div className='sticker'  key={sticker}
              onClick={() => handleStickerAdd(index)} 
              style={{'backgroundImage': `url(${sticker})`}}>
            </div>
          ))}
        </div>
      </div>
      <div className='select-bg-container'>
        <div className='heading'>Add Background</div>
        <div className='sub-heading'>Select 1</div>
        <div className='bgs-preview'>
        <div className='bg clear' 
              onClick={() => handleChangeBackground(-1)}>
                <i className='icon-fk icon-fk-cancel-circle'></i>
                Clear
            </div>
          {backgrounds.map((bg, index) => (
            <div className='bg' key={bg}
              onClick={() => handleChangeBackground(index)} 
              style={{'backgroundImage': `url(${backgrounds[index]})`}}>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
