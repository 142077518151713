import React, { Component } from "react";

import "./cardv2.css";
import { getEmbedThemeInfo } from "../theme/Loader";
import { findHashtags, triggerSAEvent } from "../utils";
// import twitterbg from '../assets/twitterbg.png';
import defaultUser from './../assets/default.jpg';

export default class Cardv2 extends Component {
  authorName() {
    let { post } = this.props;
    return <div className="name">{post.getAuthorName()}</div>;
  }

  truncate = (s = "", portrait = false) => {
    let charLimit = getEmbedThemeInfo().char_text_limit || 90;
    let charLimitPortrait = getEmbedThemeInfo().char_limit_portrait || 50;
    if (portrait) {
      if (s.length <= charLimitPortrait) {
        return s;
      }
      return s.slice(0, charLimitPortrait).concat("...");
    }
    if (s.length <= charLimit) {
      return s;
    } else {
      return s.slice(0, charLimit).concat("...");
    }
  };

  getLikes() {
    let { post } = this.props;
    if (post.getLikes() > 0) {
      return (
        <span onClick={this.likeAction}>
          <i className="like-icon-fk icon-fk-heart-empty-1" />
          <span className="like-count">{this.formatNum(post.getLikes())}</span>
        </span>
      );
    }
    return <i className="like-icon-fk icon-fk-heart-empty-1" onClick={this.likeAction} />;
  }
  getSource() {
    let { post } = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
      return (
        <span className="src-twitter">
          <i className="icon-fk-twitter-1" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === "instagram") {
      return (
        <span className="src-instagram">
          <i className="icon-fk-instagram-1" />
        </span>
      );
    }
    return null;
  }
  formatNum = (num) => {
    if (num > 999) {
      return (num / 1000).toFixed(1) + "k";
    }
    return num;
  }

  getRetweets() {
    let { post } = this.props;
    if (post.getRetweets() > 0) {
      return (
        <span onClick={this.retweetAction}>
          <i className="retweet-icon-fk icon-fk-retweet" />
          <span className="retweet-count">{this.formatNum(post.getRetweets())}</span>
        </span>
      );
    }
    return <i onClick={this.retweetAction} className="retweet-icon-fk icon-fk-retweet" />;
  }

  likeAction = () => {
    let { post } = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
      window.open(`https://twitter.com/intent/like?tweet_id=${post.getNativeId()}`, '', "height=400,width=400")
      triggerSAEvent(`like from highlights`)
    }
  }

  retweetAction = () => {
    let { post } = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
      window.open(`https://twitter.com/intent/retweet?tweet_id=${post.getNativeId()}`, '', "height=400,width=400")
      triggerSAEvent(`retweet from highlights`)
    }
  }

  replyAction = () => {
    let { post } = this.props;
    let hashtags = findHashtags(post.getText())
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
      window.open(`https://twitter.com/intent/tweet?in_reply_to=${post.getNativeId()}&hashtags=${hashtags.join(',')}`, '', "height=400,width=400")
      triggerSAEvent(`reply from highlights`)
    }
  }

  renderImage() {
    return null;
  }
  trigger = () => {
    triggerSAEvent(`visit post from highlights`)
  }

  render() {
    let { post, showPopup, portrait } = this.props;
    // console.log('post', post);
    return (
      <div className={`cardv2 ${portrait ? "portrait" : ""}`}>
        <a href={`${post.getUrl()}`} target="_blank" rel="noopener noreferrer" onClick={this.trigger}>
          <div
            className="image"
            style={{ backgroundImage: `url(${post.getMediaUrl()})` }}
          >
            {this.renderImage()}
            <div className="source">{this.getSource()}</div>
          </div>
        </a>
        <div className="details">
          <div className="author">
            <img className="avatar" src={post.getAuthorPhoto() || defaultUser} alt="" />
            {this.authorName()}
            <div className="handle">{post.getAuthorHandle()}</div>
            <span className="created-at">{post.getCreatedAt()}</span>
          </div>
          <div className="msg" onClick={() => showPopup(post)}>
            {this.truncate(post.getText(), post.isPortrait())}
          </div>
        </div>
        <div className="stats">
          <i className="reply-icon-fk icon-fk-reply-all" onClick={this.replyAction} />
          {this.getRetweets()}
          {this.getLikes()}
        </div>
      </div>
    );
  }
}
