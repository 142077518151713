import React, {useState, useRef} from 'react';
import {useInterval} from '../../hooks/useInterval';
import {convertSecondsToHourMinute} from '../../utils.js';
// import './videoplayer.css';

export const VideoPlayer = ({src, width, height, duration=0, poster }) => {
  const [togglePlay, setToggglePlay] = useState(false); // false for pause true for play
  // const [duration, setDuration] = useState(0);
  const [seek, setSeek] = useState(0);
  const [actualDuration] = useState(duration);
  const videoPlayer = useRef(null);
  useInterval(() => {
    if(togglePlay && seek < actualDuration) {
      setSeek(seek+1);
    } else if( seek === actualDuration) {
      setSeek(0)
      setToggglePlay(false);
    }
  }, 1000)
  const changePlayPause = () => {
    togglePlay ? videoPlayer.current.pause() : videoPlayer.current.play();
    setToggglePlay(!togglePlay);
    // startSeekBar();
  }
  return (
    <div className='player-container'>
      <div className={`${togglePlay ? 'pause': 'play'}-overlay-container`} onClick={changePlayPause}>
        <div className={`${togglePlay ? 'pause': 'play'}-overlay`}></div>
      </div>
      <video ref={videoPlayer} onClick={changePlayPause} id='video-player' src={src} preload="metadata" poster={poster}>
      </video>
      <div className='video-controls'>
        <div className='video-actions'>
          <div className={`${togglePlay ? 'pause': 'play'}`} onClick={changePlayPause}>
            
          </div>
          <div className='seek-container'>
            <div className='seek' style={{
              width: `${!togglePlay ? '1%' : seek*100/actualDuration}%`,
              // height: '2px'
            }}></div>
          </div>
        </div>
        <div className='video-info'>
          <div className='current'>
            {convertSecondsToHourMinute(seek)}
          </div>
          <div className='remaining'>
            {`${convertSecondsToHourMinute(actualDuration - seek)}`}
          </div>
        </div>
      </div>
    </div>
  )
}
